import React,{ useEffect, useCallback, useRef, useState } from 'react'
import '../Admin-Css/AdminHeader.css'
import { Link,useNavigate } from 'react-router-dom'
import ChatBoat from '../../Assests/images/chatbot_logo.png'
import ProfileSvg from '../../Assests/images/profile_svg.svg'
import SignOutSvg from '../../Assests/images/signout_svg.svg'
import AdminProfile from '../../Assests/images/admin_profile.svg'
function AdminHeader() {
    const admin_name = localStorage.getItem('admin_name');
    const admin_email = localStorage.getItem('admin_email');
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        //navigate('/admin_login');
        window.location.href = '/admin_login';
    };
    useEffect(() => {
        if (!admin_email) {
            navigate('/admin_login');
        }
    }, [admin_email, navigate]);

    return (
        <div className='admin_header'>
            <div className='admin_header_wrapper'>
                <div className='admin_header_container'>
                    <nav className="navbar navbar-expand-lg nav_bg shadow">
                        <a className="navbar-brand" href='/'>
                            <div className='logo_div'>
                                <img src={ChatBoat} alt="Brand Logo" />
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-lg-0 mt-4">
                                {/*<li className="nav-item">
                                    <button className='btn customer_support_message_btn'>
                                        <p>Customer Support Message</p>
                                        <div className='customer_support_message_count_div'>
                                            <p className='msgs_conut_support_msg'>5</p>
                                        </div>
                                    </button>
    </li>*/}

                                <li className="nav-item d-lg-flex align-items-center">
                                    <div className="btn-group dropdown_container">
                                        <button type="button" className="btn admin_profile_menu dropdown-toggle d-flex align-items-center justify-content-center flex-column p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className='profile_img'>
                                                <img src={AdminProfile} alt='icon'/>
                                                <div>{admin_name}</div>
                                            </div>
                                        </button>
                                        <div className="dropdown-menu dropdown_menu_style dropdown-menu-right shadow">
                                            <div className='triangle_pointer'></div>
                                            <button className="dropdown-item dropdown_style mb-1" type="button">
                                                <Link to='/change_password'>
                                                    <img src={ProfileSvg} alt='icon' />
                                                    <span>Set Password</span>
                                                </Link>
                                            </button>
                                            <hr />
                                            <button className="dropdown-item dropdown_style" onClick={logout} type="button">
                                                <img src={SignOutSvg} alt='icon' />
                                                <span>Sign Out</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default AdminHeader