import React from "react";
import "../Css/Footer.css";
import ChatBoat from "../Assests/images/logoNew.png";
import FaceBook from "../Assests/images/facebook.svg";
import Twitter from "../Assests/images/twitter.svg";
import LinkedIn from "../Assests/images/linkedin.svg";
import Mail from "../Assests/images/mail.png";
import Phone from "../Assests/images/phone.png";
import MapPin from "../Assests/images/map-pin.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_wrapper">
        <div className="footer_container container-lg">
          <div className="footer_row_gap row">
            <div className="left_section col-md-4 col-sm-10 m-md-auto">
              <div className="brand_logo">
                <img src={ChatBoat} alt="Logo" />
              </div>
              <div className="footer_text">
                <p>
                  Triosoft AI Chatbot is a platform for creating GPT chatbots
                  using your own data. These chatbots can answer questions and
                  be embedded on websites.
                </p>
              </div>
              <div className="social_media">
                <div>
                  <a href="/">
                    <img src={FaceBook} alt="icon" />
                  </a>
                </div>
                <div>
                  <a href="/">
                    <img src={Twitter} alt="icon" />
                  </a>
                </div>
                <div>
                  <a href="/">
                    <img src={LinkedIn} alt="icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="right_section col-md-8 col-sm-10 m-md-auto">
              <div className="row m-0">
                <div className="col-md-3 col-sm-4 m-md-auto marginbottomAfter576">
                  <div className="privacy">
                    <h5>Privacy</h5>
                    <ul>
                      <li>
                        <a href="/">
                          <span>How it works</span>
                        </a>
                      </li>
                      <li>
                        <Link to="/faqs">
                          <span>FAQs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pricing">
                          <span>Pricing</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/friends_chatbot">
                          <span>Affiliate</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy_policy">
                          <span>Privacy policy</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms_of_use">
                          <span>Terms of use</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-7 col-sm-8">
                  <div className="contact_info">
                    <h5>Contact Info</h5>
                    <ul>
                      <li>
                        <a className="contactFlex" href="/">
                          <img src={Mail} alt="icon" />
                          <span>support@triosoft.ai</span>
                        </a>
                      </li>
                      <li>
                        <a className="contactFlex" href="/">
                          <img src={Phone} alt="icon" />
                          <span>+91 7880016644</span>
                        </a>
                      </li>
                      <li>
                        <a className="contactFlex" href="/">
                          <img src={MapPin} alt="icon" />
                          <span>
                            Triosoft Technologies Pvt. Ltd., Plot No. 96, 2nd
                            Floor, Gargi Rani Complex, Opposite Indian Overseas
                            Bank, M.P. Nagar, Zone-II, Bhopal, Madhya Pradesh
                            India- 462011
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_div row">
            <div className="copyright_text col-md-4 col-sm-8 m-auto">
              <p>© 2024 Triosoft. All rights reserved</p>
            </div>
            {/*
                        <div className='col-lg-4 col-md-5 col-sm-8 col-12 m-auto px-xl-5'>
                            <Link to='/sign_up'>
                                <div className='btn free_signup_btn'>
                                    <p>Sign up for free</p>
                                    <span className='arrow_div'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="30px" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </div>
    */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
