import React, { useState, useEffect } from "react";
import "../Css/AddToWebsite.css";
import axios from 'axios';
import { appauth_key, chatboat_setting_update } from '../ServiceConnection/serviceconnection.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddToWebsite({ chatbotdata }) {
  const [embededOnWebsite, SetEmbededOnWebsite] = useState(false);
  const [accessibilityio, Setaccessibilityio] = useState(false);
  const [apikey, SetApiKey] = useState();
  
  const scriptTemplate = `<script src='https://chatnext.co/react_api/popupapi_new.js' id-key-for-chatboat='${chatbotdata.chatbot_id}'></script>`;

  useEffect(() => {
    console.log(chatbotdata.accessibility);
    if(chatbotdata.accessibility==true){
      SetEmbededOnWebsite(chatbotdata.Embed_on_Website)
    }else{
      SetEmbededOnWebsite(false)
    }
    SetApiKey(chatbotdata.chatbot_id);
  }, [chatbotdata]);

  const handleCheckboxToogle = (e) => {
    const { checked } = e.target;
    SetEmbededOnWebsite(checked);
    if (!checked) {
      SetEmbededOnWebsite(false)
    }
  };

  useEffect(() => {

  }, [handleCheckboxToogle]);

  // Error Message
  function showErrorMsg(msg) {
    toast.error(msg, {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  function showSuccessMsg() {

    toast.success('Updated Succesfully!', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  const validateForm = () => {
    var isValid = true;
    if (isValid === true) {
      if (isValid === true) {
        const formData = new FormData(document.getElementById('save_form')); // Get form data
        formData.append('appauth_key', appauth_key);
        const jsonData = {};
        for (let [key, value] of formData.entries()) {
          //if(filesing)

          jsonData[key] = value;
        }
        jsonData['Chatbot_id'] = chatbotdata.chatbot_id;
        jsonData['Embed_on_Website'] = embededOnWebsite;
        if(embededOnWebsite==true){
          jsonData['Accessibility'] = true;  
        }else{
          jsonData['Accessibility'] = false;  
        }
        saving_data(jsonData); // Pass the form data to the saving_data function
        console.log(jsonData);
      }
    }
  };

  const saving_data = async (jsonData) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    console.log(jsonData);
    // setLoading(true);
    axios.patch(chatboat_setting_update, jsonData, headers)
      .then(function (response) {
        // setLoading(false);
        console.log(jsonData)
        if (response.data.status != 'success') {
          showErrorMsg(response.data.message);
        } else {
          showSuccessMsg();
        }
      }).catch(function (error) {
        showErrorMsg(error.message);
        // setLoading(false);
      });
  };

  return (
    <div className="add_to_website">
      <div className="add_to_website_wrapper">
        <div className="add_to_website_container">
          <form id='save_form'>
            <div>
              <label className="label_style nowrap_text1">Embed on website</label>
              <label className="switch">
                <input type="checkbox"
                  name="Embed_on_Website"
                  checked={embededOnWebsite}
                  onChange={handleCheckboxToogle}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className={embededOnWebsite ? 'input_textareas' : 'input_textareas input_textareas_website_disabled'}>
              <div className="input_div_website">
                <label>
                  Copy and add the following script to your website html:
                </label>
                <br />

                <textarea
                  rows="4"
                  cols="50"
                  defaultValue={scriptTemplate}
                  placeholder="Paste your script here..."
                />
              </div>

              {/*
              <div className="input_div_website">
                <label>
                  Or use iframe if you want to embed to specific section:
                </label>
                <br />
                <textarea
                  rows="4"
                  cols="50"
                  placeholder="Paste your iframe here..."
                ></textarea>
              </div>
                    */}
            </div>
          </form>
          <div className="update_btn_div">
            <button type="button" className="btn update_btn" onClick={validateForm}>Update</button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddToWebsite;
