import React, { useEffect, useState,useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Css/CreateByText.css";
import "../Css/CreateChatbotFooter.css";
// import CustomerService from "../Assests/images/customer_service_icon.svg";
import DataSearchIcon from "../Assests/images/data_answer_icon.svg";
import DeletSweep from "../Assests/images/delete-sweep.svg";
import TextIcon from "../Assests/images/text_icon.svg";
import FileIcon from "../Assests/images/file_icon.svg";
import WebIcon from "../Assests/images/website_icon.svg";
import SimpleSearch from "../Assests/images/simple_search.svg";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Success from '../Assests/images/success_gif.gif'
import { ToastContainer, toast } from 'react-toastify';
import { showErrorMsg, showSuccessMsg } from './ToastNotifications';
import {
  domain_urls,
  url_scraped,
  chatboat_api_save,
  chatboat_text_save,
} from "../ServiceConnection/serviceconnection.js";
// import Loader from "./Interface";
import Header from "./Header";
let setStopWebCrawl = '0';
let variable_for_test="";
function CreateChatbot01() {

  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [ChatboatName, SetChatboatName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalSave, setShowModalSave] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalData, setshowErrorModalData] = useState('Remove data sources with no data.');
  const [characterCount, setCharacterCount] = useState(0);
  const data_source = localStorage.getItem("data_source");
  const [dataArray, setDataArray] = useState([data_source]);
  const [activedatasrc, setactivedatasrc] = useState(data_source);
  const [loading, setLoader] = useState(false);
  // const [buttonDisabled, setButtonDisabled] = useState(false);

  const [filecharacter, setFileCharacter] = useState(0);
  const [websitecharacter, setWebsiteCharacter] = useState(0);
  const [page_found, setPage_found] = useState(0);
  const [page_crawled, setPage_crawled] = useState(0);
  const [url_characters_found, setUrl_characters_found] = useState(0);
  const [textcharacter, setTextCharacter] = useState(0);
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  // const [stopwebcrawl, setStopWebCrawl] = useState(0);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const navigate = useNavigate();
  //Website JS

  const [inputValue, setInputValue] = useState("");


  const handleclickevnt = useCallback(() => {
    // console.log('yes call '+activedatasrc);
    setactivedatasrc(activedatasrc);
    variable_for_test=activedatasrc;
    setActiveTab(activedatasrc);
    let dtsrvb = activedatasrc + "_cls";
    $(".comn_cls").removeClass("selected_tab");
    $("." + dtsrvb).addClass("selected_tab");
    
  }, [activedatasrc]);
  
  useEffect(() => {
    handleclickevnt();
  }, [activedatasrc]);
  
  const handleTabClick = (tab) => {;
    if(tab!==""){
    // console.log(tab);
    setactivedatasrc(tab);
    }else{
    let dtsrvb = variable_for_test + "_cls";
    $("." + dtsrvb).addClass("selected_tab");
    }
  };
  
  

  const handledropdownClick = (tab) => {
    // console.log(tab+" "+"dddclick" )
    setactivedatasrc(tab);
    variable_for_test=tab;
    const updatedArray = [...dataArray, tab];
    const dynma = '.' + tab + '_chractr';
    $(dynma).addClass('plain_text');
    setDataArray(updatedArray);
  };
  const charter_fnctn = (value) => {
    const chrt_h = value + "_chractr";
    $("." + chrt_h).removeClass("hidden_character");
  };

  const datasoucecond = useCallback(() => {
    $(".comn_cls").addClass("hidden_tabs");
    $(".chr_cm_cls").addClass("hidden_character");
    $(".drop_cm_cls").removeClass("hidden_dropdown");
    if (dataArray.length === 0) { // Removed quotes around 0
      navigate("/create_chatbot_options");
    } else {
      dataArray.forEach(function (value) {
        $(".comn_cls").removeClass("selected_tab");
        const dtsr = value + "_cls";
        $("." + dtsr).removeClass("hidden_tabs");
        //handleTabClick(value);
        charter_fnctn(value);
        const dtsr1 = value + "_drop_down";
        $("." + dtsr1).addClass("hidden_dropdown");
      });
     handleTabClick("");
    }
  }, [dataArray, navigate]); // Add dependencies as needed
  
  useEffect(() => {
    if (!data_source) {
      navigate("/create_chatbot_options");
    } else {
      datasoucecond();
      setTotalCharacterCount(filecharacter + textcharacter + websitecharacter);
  
      ['text', 'file', 'website'].forEach(className => {
        let charactering;
        if (className === 'text') {
          charactering = textcharacter;
        } else if (className === 'file') {
          charactering = filecharacter;
        } else if (className === 'website') {
          charactering = websitecharacter;
        }
        const dynma = '.' + className + '_chractr';
        const dynma_snd = '.' + className + '_cls';
        if (charactering < 11 && !$(dynma).hasClass('hidden_character')) {
          $(dynma).addClass('plain_text');
        } else {
          $(dynma).removeClass('plain_text');
        }
      });
    }
  }, [filecharacter, textcharacter, websitecharacter, dataArray, datasoucecond, data_source, navigate]); // Add dependencies as needed
  

 

  //Remove Data Souce
  const removedatasource = () => {
    const updatedArray = dataArray.filter((data) => data !== activedatasrc);
    handleTabClick(dataArray[0]);
    setDataArray(updatedArray);
    // console.log('delete done');
    if (activedatasrc === 'text') {
      setTextCharacter(0);
      $('.textinput').val('');
      $('.text_chractr').removeClass('plain_text');
    } else if (activedatasrc === 'file') {
      setSelectedFiles('');
      setFileCharacter(0);
      $('.file_chractr').removeClass('plain_text');
    } else if (activedatasrc === 'website') {
      setStopWebCrawl = '1';
      // console.log(setStopWebCrawl);
      setUrls('');
      setWebsiteCharacter(0);
      setCheckedBoxes('');
      setPage_found(0);
      setPage_crawled(0);
      setUrl_characters_found(0);
      $('.website_chractr').removeClass('plain_text');
    }
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowErrorModal(false);
  };
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const TextKeyup = (event) => {
    const inputText = event.target.value;
    setTextCharacter(inputText.length);
  };


  const filelist = (event, chtrln) => {
    const files = Array.from(event.target.files);
    const fileData = files.map((file) => {
      let fileSize = (file.size / (1024 * 1024)).toFixed(2); // Convert to MB

      if (fileSize === "0.00") {
        // If size is 0.00 MB, convert to KB
        fileSize = (file.size / 1024).toFixed(2) + " KB";
      } else {
        fileSize += " MB";
      }
      return {
        name: file.name,
        size: fileSize,
        type: file.type,
        lastModified: file.lastModified,
        chtrln: chtrln,
        evnt: event.target.files,
      };
    });
    // console.log(fileData);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...fileData]);
    setFileCharacter((prevCount) => prevCount + chtrln);
  };




  
  const handleDeleteFile = (index) => {
    // console.log(index);
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      const deletedFile = updatedFiles.splice(index, 1)[0]; // Remove the file at the specified index
      const deletedCharacterCount = deletedFile.chtrln; // Get the character count of the deleted file
      setFileCharacter((prevCount) => prevCount - deletedCharacterCount); // Subtract the character count of the deleted file from the total count

      return updatedFiles;
    });
  };

  const save_chatboat = () => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };


    let data_source_update = '';
    let stop_save = '0';

    ['text', 'file', 'website'].forEach(className => {
      let charactering;
      if (className === 'text') {
        charactering = textcharacter;
      } else if (className === 'file') {
        charactering = filecharacter;
      } else if (className === 'website') {
        charactering = websitecharacter;
      }
      const dynma = className + '_chractr';
      if ((charactering) >= 11) {
        if (data_source_update === '') {
          data_source_update = className;
        } else {
          data_source_update = data_source_update + ',' + className;
        }

      }

      const o = $('.' + dynma).hasClass('plain_text');
      if (o) {
        stop_save = '1';
      }


    });

    if (stop_save === '1') {
      setshowErrorModalData('Remove data sources with no data.');
      setShowErrorModal(true);
      return;
    }
    
    if (!ChatboatName) {
      setshowErrorModalData('chatbot name is mandatory');
      setShowErrorModal(true);
      return;
    }
    
    // Create a new FormData object
    const formData = new FormData();
    formData.append("chatbot_name", $('.chatboat_name').val());
    formData.append("customer_name", localStorage.getItem('user_id'));
    formData.append("data_source", data_source_update); 
    formData.append("plan_types", '1'); 
    
    if (textcharacter > 0) {
      formData.append("text", 'True'); 
      formData.append("text_content", $('.textinput').val());
    }else{
      formData.append("text", 'False');
    }
    if (selectedFiles.length > 0) {
      formData.append("file", 'True');
       selectedFiles.forEach((file) => {
        formData.append("files[]", file.evnt[0]);
      }); 
    }else{
      formData.append("file", 'False');
    }

    if (websitecharacter > 0) {
      formData.append("Web", 'True'); 
      const datanyo = checkedBoxes.map((chkbx_lop, indexn) => {
      console.log(urls[chkbx_lop]);
       formData.append("weburl[]", JSON.stringify(urls[chkbx_lop]));
      });
    }else{
      formData.append("Web", 'False'); 
    }
    //console.log(weburl);
     /* 
    const jsonDatanew = {};
    jsonDatanew["text_content"] = $('.textinput').val();
    jsonDatanew["plan_types"] = '1';
          jsonDatanew["chatbot_name"] = $('.chatboat_name').val();
          jsonDatanew["customer_name"] = localStorage.getItem('user_id');
          jsonDatanew["file"] = '1';
          jsonDatanew["text"] = '12';
          jsonDatanew["Web"] = '1'; */
          save_chatboat_final(formData);
return '1';
const jsonData = {};
jsonData["content"] = $('.textinput').val();
    axios
      .post(chatboat_text_save, jsonData, headers)
      .then(function (response) {
        // console.log(response);
        // console.log(response.data);
       
       // setShowModalSave(true);

        // Session Storage 
        if (response.data.status !== "success") {
          setLoading(false);
          $(".server_error").text(response.data.error.message);
        } else {
          const jsonDatanew = {};
          jsonDatanew["plan_types"] = '1';
          jsonDatanew["chatbot_name"] = $('.chatboat_name').val();
          jsonDatanew["customer_name"] = localStorage.getItem('user_id');
          jsonDatanew["file"] = '1';
          jsonDatanew["text"] = response.data.data.id;
          jsonDatanew["Web"] = '1';
// console.log(jsonDatanew);
          save_chatboat_final(jsonDatanew);
        }
      })
      .catch(function (error) {
        //setShowModalSave(true);
        //console.error(error);
        /* if(error.response.data.msg){
            $('.server_error').text(error.response.data.msg);
            }else{
                $('.server_error').text(error.message);
            } */
        // Handle the error here
        setLoading(false);
        // You can display an error message or perform any necessary actions
      });
    
  };



  const save_chatboat_final = (jsonDatanew) => {
    // const headers = {
    //   "Content-Type": "application/json", // Change the content type to handle file upload
    // };
    if(localStorage.getItem('chatboat_lmt')>=localStorage.getItem('chatboat_created')){
        
    }else{
      showErrorMsg('Chatbot Limit Exceeded');
      return;
    } 

    if(parseInt(totalCharacterCount) > parseInt(localStorage.getItem('char_lmt'))) {
      showErrorMsg('Characters Limit Exceeded');
      return;
  }

      setLoading(true);
    axios.defaults.withCredentials = true
    const csrftoken = getCookie('csrftoken');
    axios
      .post(chatboat_api_save, jsonDatanew, {
        headers: {
          'X-CSRFToken': csrftoken // Include the CSRF token in the header
        },
      })
      .then(function (response) {
         console.log(response);
        setLoading(false);

        // Session Storage 
        if (response.data.status !== "success"){
          $(".server_error").text(response.data.error.message);
        } else {
          setShowModalSave(true);
          const chatboat_created = parseInt(localStorage.getItem('chatboat_created')) + parseInt(1);
          localStorage.setItem('chatboat_created',chatboat_created);
        }
      })
      .catch(function(error){
        showErrorMsg(error.response.data.message);
        setLoading(false);
      });
    
  };

  const closeModal = () => {
    setShowModalSave(false);
    navigate("/my_chatbots");
  };


  const addUrl = () => {
    if (inputValue.trim() === "") {
      alert("Please enter a URL.");
      return;
    }
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if(!urlPattern.test(inputValue.trim())) {
      alert("Please enter a URL.");
      return;
    }
    $('.crawl_btn').addClass('disabled');
    $('.save_chatboat').addClass('disabled');
    // console.log(inputValue);
    setLoader(true);
    //setButtonDisabled(true);
    setUrls('');
    setWebsiteCharacter(0);
    setCheckedBoxes('');
    setPage_found(0);
    setPage_crawled(0);
    setUrl_characters_found(0);
    const jsonData = {
      flg: "1",
      domain: inputValue,
    };
    // https://ekonacademy.com/
    setStopWebCrawl='0';//crawl start 
    axios.defaults.withCredentials = true
    const csrftoken = getCookie('csrftoken');
      axios.post(domain_urls, jsonData, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken // Include the CSRF token in the header
        },
      })
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data);

      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        $('.crawl_btn').removeClass('disabled');
        setLoader(false);
      });
  };
  // Function to get the CSRF token from the cookie
function getCookie(name) {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
}

  const url_showfn = (data) => {
    if(setStopWebCrawl==0){  
    const urlsArray = [
      { flg: '2', url: data.current_scraped,charctr: data.lengthvl,charctr_text: data.content, status: data.status }
    ];

    
    
    // console.log(urlsArray);
    setUrls((prevSelectedUrls) => [...prevSelectedUrls, ...urlsArray]);
    setPage_found((prevCount) => {
      const newCount = prevCount + 1;
    
      if (newCount === 15) {
        //setStopWebCrawl=1; ////if i uncomment this so crawl will stop after 15 url crawl
      }
    
      return newCount;
    });

    
    setPage_crawled((prevCount) => prevCount + 1);
    setUrl_characters_found((prevCount) => prevCount + data.lengthvl);
    if (data.next_scraped == 'done') {
      $('.crawl_btn').removeClass('disabled');
      $('.save_chatboat').removeClass('disabled');
      setLoader(false);
    } else { //url scrapped done
      
      addUrl2();
      
    }
  }else{
    $('.crawl_btn').removeClass('disabled');
      $('.save_chatboat').removeClass('disabled');
      setLoader(false);
  }
  
  }; 


  const crawl_stopper = () => {
    setStopWebCrawl=1;
  }; 
  const addUrl2 = () => {
    
    
    axios.defaults.withCredentials = true;
    axios
      .get(domain_urls)
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data); 
      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        $('.crawl_btn').removeClass('disabled');
        setLoader(false);
      });
  };

  const CheckUncheckBox = (index) => {
    // Check if the checkbox is already checked
    const updatedwebchr = [...urls];
    const slchk = updatedwebchr.splice(index, 1)[0]; // Remove the file at the specified index
    // console.log(slchk.charctr);
    const chtrlnts = slchk.charctr; // Get the character count of the deleted file
    if (checkedBoxes.includes(index)) {
      // Checkbox is already checked, so uncheck it
      setCheckedBoxes(checkedBoxes.filter((item) => item !== index));
      setWebsiteCharacter((prevCount) => prevCount - chtrlnts);
    } else {
      // Checkbox is unchecked, so check it
      setCheckedBoxes([...checkedBoxes, index]);
      setWebsiteCharacter((prevCount) => prevCount + chtrlnts);
    }
  };

  // const deleteUrl = (index) => {
  //   const updatedUrls = urls.filter((_, i) => i !== index);
  //   setUrls(updatedUrls);
  // };



  // const setActiveTabStyling = (tab) => {
  //   $(".icon_text_div").removeClass("active");
  //   $(`#${tab}`).addClass("active");
  // };

  const countCharactersbyfile = async (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === "txt") {
      textfilecount(event);
    } else if (extension === "pdf") {
      countCharacters(event);
    }
  };
  const countCharacters = async (event) => {
    const file = event.target.files[0];
    const eventns = event;

    const pdfjsLib = await import("pdfjs-dist/build/pdf");

    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

    const fileReader = new FileReader();
    fileReader.onload = async function (event) {
      const typedArray = new Uint8Array(event.target.result);
      const loadingTask = pdfjsLib.getDocument(typedArray);

      try {
        const pdf = await loadingTask.promise;

        let totalCharacters = 0;

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const text = content.items.map((item) => item.str).join("");
          //console.log(text);
          totalCharacters += text.length;
        }

        setCharacterCount(totalCharacters);
        filelist(eventns, totalCharacters);
      } catch (error) {
        console.error(error);
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  const textfilecount = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        const count = contents.length;
        filelist(event, count);
        setCharacterCount(count);
      };

      reader.readAsText(file);
    }
  };

  


/*   const [stopLoop, setStopLoop] = useState(false);

  const startLoop = () => {
    // Reset the stopLoop flag before starting the loop
    setStopLoop(false);

    for (let i = 1; i <= 10000; i++) {
      // console.log('Loop iteration:', i);

      // Perform your desired actions within the loop here

      // Check the stopLoop flag
      if (stopLoop) {
        // console.log('Loop stopped.');
        break;
      }
    }
  };

  // console.log(startLoop());
 */
  // const stopLoopExecution = () => {
  //   setStopLoop(true);
  // };

  const tooltipData =
    "Your data source should include details about your products, such as your company, book content, or any other information that you want your chatbot to be aware of. \n However, to customize the behavior of chatbot, you should use the System Prompt feature in the Settings after creating it. It is recommended to separate each section with double lines for better performance.";

  return (

    <div className="create_by_text  ">
      {/* <div>
        <Header />
      </div> */}
      <div className="create_by_text_wrapper">
        <div className="create_by_text_continer container-lg">
          <h1>Create Chatbot</h1>
          <div className="name_btn_div">
            <div className="name_btn_div_wrapper">
              <div className="bot_name">
                <label>Name</label>
                <br />
                <input
                  className="shadow chatboat_name"
                  type="text"
                  placeholder="Chatbot Name*" name='chatboat_name' value={ChatboatName}
                  onChange={(e) => SetChatboatName(e.target.value)}
                ></input>
              </div>
              <div className="tips_more_btn">
                <div className="tips_div text-end d-sm-none">
                  <div className="btn">
                    <span className="question_mark">?</span>
                    <span className="tips">Tips</span>
                  </div>
                </div>
                <div className="dropdown_div">
                  <button
                    className="btn add_more_btn dropdown-toggle shadow"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    + Add more data source
                  </button>
                  {/*   <button onClick={startLoop}>Start Loop</button>
      <button onClick={stopLoopExecution}>Stop Loop</button> */}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item drop_cm_cls f text_drop_down"
                      onClick={() => handledropdownClick("text")}
                    >
                      <img src={TextIcon} alt="icon" />
                      <p>Text</p>
                    </div>
                    <div
                      className="dropdown-item drop_cm_cls file_drop_down"
                      onClick={() => handledropdownClick("file")}
                    >
                      <img src={FileIcon} alt="icon" />
                      <p>File</p>
                    </div>
                    <div
                      className="dropdown-item drop_cm_cls website_drop_down"
                      onClick={() => handledropdownClick("website")}
                    >
                      <img src={WebIcon} alt="icon" />
                      <p>Website</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tips_div text-end d-sm-block d-none">
              <div className="btn" id="app-title">
                <span className="question_mark">?</span>
                <span className="tips">Tips</span>
              </div>
              <ReactTooltip
                className="react_tooltip shadow"
                anchorId="app-title"
                place="left"
                content={tooltipData}
              />
            </div>
          </div>
          <div className="text_container">
            <div className="textarea_wrapper">
              <div className="textarea_container shadow">
                <div className="textarea_head d-flex justify-content-between align-items-end">
                  <div className="create_options_header">
                    {/* Tabs */}
                    <div
                      className={`icon_text_div d-flex align-items-center text_cls comn_cls `}
                      onClick={() => handleTabClick("text")}
                    >
                      <img src={TextIcon} alt="icon" />
                      <h4>Text</h4>
                    </div>
                    <div
                      className={`icon_text_div d-flex align-items-center file_cls comn_cls `}
                      onClick={() => handleTabClick("file")}
                    >
                      <img src={FileIcon} alt="icon" />
                      <h4>File</h4>
                    </div>
                    <div
                      className={`icon_text_div d-flex align-items-center website_cls comn_cls`}
                      onClick={() => handleTabClick("website")}
                    >
                      <img src={WebIcon} alt="icon" />
                      <h4>Website</h4>
                    </div>
                    {/* ...existing code... */}
                  </div>
                  <div>
                    <span className="data_source">Data source*</span>
                  </div>
                </div>
                {/* Tab Show */}
                {/* {renderTabContent()} */}
                {/* Text Tab */}
                <div
                  className={`text_tab ${activeTab === "text" ? "display_block_important" : ""
                    }`}
                >
                  <div className="text_input">
                    <textarea onKeyUp={TextKeyup} className='textinput' placeholder="Piece of information, can be company info, book content..."></textarea>
                  </div>
                </div>
                {/* File Tab */}
                <div
                  className={`file_tab ${activeTab === "file" ? "display_block_important" : ""
                    }`}
                >
                  <div className="textarea_head d-flex justify-content-between align-items-end">
                    <div className="ml-auto mt-2">
                      <input
                        id="add_file"
                        multiple
                        accept=".txt, .pdf"
                        onChange={countCharactersbyfile}
                        type="file"
                        className="d-none"
                      ></input>
                      <label className="btn add_file_btn" htmlFor="add_file">
                        + Add Files
                      </label>
                    </div>
                  </div>
                  <div className="text_input">
                    <div className="file_table_div">
                      <div className="file_table">
                        {selectedFiles.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th>NAME</th>
                                <th>SIZE</th>
                                <th>CHARACTERS</th>
                                <th>STATUS</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="custom_tbody">
                              {selectedFiles.map((file, index) => (
                                <tr key={index}>
                                  <td>{file.name}</td>
                                  <td>{file.size} </td>
                                  <td>{file.chtrln} </td>
                                  <td>
                                    <div className="processed">
                                      <p>Processed</p>
                                    </div>
                                  </td>
                                  <td className="css-aq1g2j">
                                    <div
                                      className="delete_icon"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <img src={DeletSweep} alt="icon" />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="no_file_text">
                            <p>No File Yet.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Website Tab */}
                <div
                  className={`website_tab ${activeTab === "website" ? "display_block_important" : ""
                    }`}
                >
                  <div className="text_input">
                    <div className="website_container">
                      <h5 className="url_label">
                        Website Url:<span>*</span>
                      </h5>
                      <div className="url_input">
                        <input
                          type="url"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          placeholder="website must start with https://"
                        />
                        

                          
                            {loading ? (
                              <div
                              className={`btn crawl_stop ${inputValue.trim() === "" ? "disabled" : ""}`}
                              onClick={crawl_stopper}
                              >
                                <span>
                                  <div class='crawl_stopper'>
                                    <div className="loader_round_crawl"></div>
                                    <span className="crawl_text">Stop</span>
                                  </div>
                                </span>
                              </div>
                            ) : (
                              <div
                          className={`btn crawl_btn ${inputValue.trim() === "" ? "disabled" : ""}`}
                          onClick={addUrl}
                        >
                              <span>
                                
                                <span><img src={SimpleSearch} alt="icon" /></span>
                                <span className="crawl_text">Crawl</span> 
                                </span>
                                </div>
                              
                            )}
                          

                        
                      </div>
                      <div className="loader_table_div">
                        {loading && (
                          <div className="linear_loader">
                            <div className="animated-background">
                              <div className="background-masker btn-divide-left"></div>
                            </div>
                          </div>
                        )}
                        {urls.length > 0 && (
                          <div className="url_table mt-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>

                                  </th>
                                  <th className="page_url">PAGE URL</th>
                                  <th>STATUS</th>
                                  <th>CHARACTER</th>
                                </tr>
                              </thead>
                              <tbody>
                                {urls.map((url, index) => (
                                  <tr key={index}>
                                    <td>
                                      {url.status == '200' ? (

                                        <input
                                          type="checkbox"
                                          checked={checkedBoxes.includes(index)}
                                          onChange={() => CheckUncheckBox(index)}
                                        />
                                      ) : (
                                        <input
                                          type="checkbox"
                                          disabled
                                        />
                                      )}
                                    </td>
                                    <td>{url.url}</td>
                                    <td>
                                      {url.status == '200' ? (
                                        <div className="processed">
                                          <p>200</p>
                                        </div>
                                      ) : (
                                        <div className="notprocessed">
                                          <p>500</p>
                                        </div>
                                      )}
                                    </td>
                                    <td>{url.charctr}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div className="table_footer">
                        <table className="w-100 text-center">
                          <tr className="footer_row">
                            <td className="table_footer_td_bg">{page_found} Pages found</td>
                            <td className="table_footer_td_bg">{page_crawled} Pages crawled</td>
                            <td className="table_footer_td_bg">{url_characters_found} Characters found</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="remove_data_source">
                  <div className="btn remove_btn" onClick={handleModalOpen}>
                    <img src={DeletSweep} alt="icon" />
                    <p>Remove this Data Source</p>
                  </div>
                </div>
                <div className="create_chatbot_footer_div p-4">
                  <div className="create_chatbot_footer">
                    <div className="create_chatbot_footer_container">
                      <div className="left_div">
                        <div className="text_counter_div">
                          <p className="txtclass chr_cm_cls text_chractr">
                            Plain Text: {textcharacter} characters
                          </p>
                          <p className="txtclass chr_cm_cls file_chractr">
                            File Text: {filecharacter} characters
                          </p>
                          <p className="txtclass chr_cm_cls website_chractr">
                            Website Text: {websitecharacter} characters
                          </p>
                        </div>
                      </div>
                      <div className="right_div" hidden>
                        <div className="chatbot_role">
                          <p>Chatbot Role:*</p>
                          <div className="role_btns">

                            <div
                              className={`btn selected answer_data_btn ${activeButton === "answerData"
                                ? "selected_btn"
                                : ""
                                }`}
                              id="selected"
                              onClick={() => handleButtonClick("answerData")}
                            >
                              <img src={DataSearchIcon} alt="icon" />
                              <div>Answer Questions About Data</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="delet_build_btns">
                      <div className="total_char_div">
                        <p className="total_text m-0">Total characters: {totalCharacterCount}</p>
                      </div>
                      <div
                        className="btn build_btn save_chatboat"
                        onClick={save_chatboat}
                      >
                        <p>Build Chatbot</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
          <Modal.Title style={{ paddingLeft: "1rem" }}>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            Are you sure you want to remove this data source?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            className="confirm_btn"
            variant="primary"
            onClick={removedatasource}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal
        show={showErrorModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >

        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            {showErrorModalData}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Ok
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal id='myModal' show={showModalSave} onHide={closeModal} centered >
        <Modal.Body className='modal_body'>
          <div className='success_img d-flex justify-content-center'>
            <img src={Success} alt='icon' />
          </div>
          <p>Successfully Save.</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn go_to_login' onClick={closeModal}>Ok</div>

        </Modal.Footer>
      </Modal>

      <div
        className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
      >
        <div className='loader'></div>
      </div>
      <ToastContainer />
    </div>


  );
}

export default CreateChatbot01;
