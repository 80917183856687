import React from 'react'
import Footer from './Footer';
import '../Css/Home.css';

function FAQs() {
    const faqs = [
        { question: 'What is Chatnext?', answer: 'Chatnext is a platform that allows you to create GPT chatbot from your own data. The chatbot can answer any questions and can be embed on website' },
        { question: 'What formats of data I can use?', answer: 'At the moment, Chatnext supports plain text, pdfs, website url. Audio files will be available soon.' },
        { question: 'Can I add chatbot to my website?', answer: 'Sure, you can add the chatbot to your website easily. All you have to do is allow public access for the chatbot and include a provided script on your website.' },
        { question: 'Does chatbot answer questions that are not included in my data?', answer: 'By default No, Chatbot will try to find the answer from the provided data only. However, you can always change this in system prompt.' },
        { question: 'Do you provide a free trial?', answer: 'Currently, we do not offer a free trial. However, you can still experience our services through our free-tier option.' }
        // Add more FAQ items as needed
    ];
    return (
        <div>
            <section className='faqs'>
                
                <div className='faqs_container'>
                    <h1>Frequently Asked Questions</h1>
                    <div className='faqs_div col-sm-9 col-11'>
                        <div className="accordion" id="accordionExample">
                            {faqs.map((faq, index) => {
                                const itemId = `collapse${index + 1}`;

                                return (
                                    <div className="accordion-item" key={itemId}>
                                        <h2 className="accordion-header" id={`heading${itemId}`}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${itemId}`}
                                                aria-expanded="false"
                                                aria-controls={itemId}
                                            >
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={itemId}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`heading${itemId}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {faq.answer}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='see_more_div'>
                            <button className='btn see_more_btn'>
                                See more <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                                    <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default FAQs