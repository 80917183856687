import MUIDataTable from "mui-datatables";
import '../Admin-Css/Subscribers.css'
import React, { useEffect, useRef, useState, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TextField } from "@mui/material";
import { user_detail } from '../../ServiceConnection/serviceconnection.js';
import axios from 'axios';
const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

function UserData() {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [mappedData, SETmappedData] = useState([]);
    const [data, SETdata] = useState([]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };


    useEffect(() => {
        const headers = {
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        axios.get(user_detail,headers)
          .then(function (response) {
            const data = response.data.data;

                // Transform the API response data to match the format expected by MUI DataTable
                const transformedData = data.map(item => ({
                    "First Name": item.first_name,
                    "Last Name": item.last_name,
                    "Email": item.email,
                }));

                // Set the transformed data in the state
                SETmappedData(transformedData);


          })
          .catch(function (error) {
            console.error(error);
            
          });
        }, []);

    const columns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email"
    ];

    const options = {
        search: true,
        download: false, // Disable the default download button in MUI DataTable
        print: true,
        customToolbar: () => (
            // Custom toolbar component with Excel download button
            <button className="custom-excel-button" onClick={handleExcelDownload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#686b6b" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                    <path d="M6 12v-2h3v2H6z" />
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                </svg>
            </button>
        ),
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        checkboxSelection: true,
        checkboxSelectionCellStyle: "custom-checked-color",
        onTableChange: (action, state) => {
            console.dir(state);
        },
        customFilterDialogFooter: () => {
            return (
                <div>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
            );
        },
        customFilterFunction: (value, dataIndex, columnMeta, filterData) => {
            const startDateFilter = new Date(startDate);
            const endDateFilter = new Date(endDate);

            const dateValue = new Date(value);

            if (startDateFilter && endDateFilter) {
                return dateValue >= startDateFilter && dateValue <= endDateFilter;
            }

            return true;
        }
    };

    const handleExcelDownload = () => {
        // Prepare the data for the Excel file
        const excelData = [
            columns.map(column => column.name),
            ...data
        ];

        // Create a new workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Convert the workbook to an Excel file buffer
        const excelBuffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

        // Save the Excel file
        const excelFile = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(excelFile, "ACME_Employee_List.xlsx");
    };

    /* const button = <div className="active_disable_buttons">
        <button className="active_btn">Active</button>
        <button className="disable_btn disable_btn_fade">Disable</button>
    </div> */

    const button = '';


    return (
        <div className='admin_dashboard'>
            <div className='admin_dashboard_wrapper'>
                <div className='admin_subscriber_container admin_dashboard_container'>
                    <div>
                        <AdminSidebar />
                    </div>
                    <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem" }}>
                        <div className="admin_subscriber_head custom_qa_head">
                            <div
                                className="btn sidebar_toggle_btn d-block d-lg-none"
                                id="sidebar_toggle_btn"
                            >
                                <div className="rotate_arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="#800080"
                                        className="bi bi-chevron-double-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="user_data_table_container">
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={createTheme()}>
                                    <MUIDataTable
                                        title={"Subscription"}
                                        data={mappedData}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserData;
