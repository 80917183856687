import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Css/CreateByText.css";
import Text from "./Text";
import File from "./File";
import Website from "./Website";
import CreateChatbotFooter from "../Components/CreateChatbotFooter";
import DeletSweep from "../Assests/images/delete-sweep.svg";
import TextIcon from "../Assests/images/text_icon.svg";
import FileIcon from "../Assests/images/file_icon.svg";
import WebIcon from "../Assests/images/website_icon.svg";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
function CreateChatbot() {
  const [activeTab, setActiveTab] = useState("text");
  const [showModal, setShowModal] = useState(false);
  const data_source = localStorage.getItem("data_source");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const setActiveTabStyling = (tab) => {
    $(".icon_text_div").removeClass("active");
    $(`#${tab}`).addClass("active");
  };

  useEffect(() => {
    if (!data_source) {
      navigate("/create_chatbot_options");
    } else {
      $(".comn_cls").hide();
      const dtsr = data_source + "_cls";
      $("." + dtsr).show();
    }
    //initial active tab styling
    setActiveTabStyling(activeTab);
  }, [activeTab, data_source, navigate]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "text":
        return <Text />;
      case "file":
        return <File />;
      case "website":
        return <Website />;
      default:
        return null;
    }
  };

  return (
    <div className="create_by_text  ">
      <div className="create_by_text_wrapper">
        <div className="create_by_text_continer container-lg">
          <h1>Create Chatbot</h1>
          <div className="name_btn_div">
            {/* ...existing code... */}
            <div className="name_btn_div_wrapper">
              <div className="bot_name">
                <label>Name</label>
                <br />
                <input
                  className="shadow"
                  type="text"
                  placeholder="Chatbot Name*"
                ></input>
              </div>
              <div className="tips_more_btn">
                <div className="tips_div text-end d-sm-none">
                  <div className="btn">
                    <span className="question_mark">?</span>
                    <span className="tips">Tips</span>
                  </div>
                </div>
                <div>
                  <button
                    className="btn add_more_btn dropdown-toggle shadow"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    + Add more data source
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="dropdown-item">
                      <img src={FileIcon} alt="icon" />
                      <p>File</p>
                    </div>
                    <div className="dropdown-item">
                      <img src={WebIcon} alt="icon" />
                      <p>Website</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tips_div text-end d-sm-block d-none">
              <div className="btn">
                <span className="question_mark">?</span>
                <span className="tips">Tips</span>
              </div>
            </div>
          </div>
          <div className="text_container">
            <div className="textarea_wrapper">
              <div className="textarea_container shadow">
                <div className="textarea_head d-flex justify-content-between align-items-end">
                  <div className="create_options_header">
                    {/* Tabs */}
                    <div
                      className={`icon_text_div d-flex align-items-center text_cls comn_cls ${
                        activeTab === "text" ? "selected_tab" : ""
                      }`}
                      onClick={() => handleTabClick("text")}
                    >
                      <img src={TextIcon} alt="icon" />
                      <h4>Text</h4>
                    </div>
                    <div
                      className={`icon_text_div d-flex align-items-center file_cls comn_cls ${
                        activeTab === "file" ? "selected_tab" : ""
                      }`}
                      onClick={() => handleTabClick("file")}
                    >
                      <img src={FileIcon} alt="icon" />
                      <h4>File</h4>
                    </div>
                    <div
                      className={`icon_text_div d-flex align-items-center website_cls comn_cls ${
                        activeTab === "website" ? "selected_tab" : ""
                      }`}
                      onClick={() => handleTabClick("website")}
                    >
                      <img src={WebIcon} alt="icon" />
                      <h4>Website</h4>
                    </div>
                    {/* ...existing code... */}
                  </div>
                  <div>
                    <span className="data_source">Data source* |</span>
                    <span className="character">&nbsp;(5 Character)</span>
                  </div>
                </div>
                {/* Tab Show */}
                {renderTabContent()}
                <div className="remove_data_source">
                  <div className="btn remove_btn" onClick={handleModalOpen}>
                    <img src={DeletSweep} alt="icon" />
                    <p>Remove this Data Source</p>
                  </div>
                </div>
                <div className="p-4">
                  <CreateChatbotFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
          <Modal.Title style={{ paddingLeft: "1rem" }}>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            Are you sure you want to remove this data source?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            className="confirm_btn"
            variant="primary"
            onClick={handleModalClose}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateChatbot;
