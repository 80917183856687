import React, { Suspense, useEffect, useState } from 'react'
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes, Router, useLocation } from "react-router-dom";
import { Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet';
// import SignIn from "./Components/SignIn";
// import SignUp from "./Components/SignUp";
// import Pricing from "./Components/Pricing";
import CreateChatbotOptions from "./Components/CreateChatbotOptions";
import ForgotPassword from "./Components/ForgotPassword";
import VerifyAccount from "./Components/VerifyAccount";
import ResetPassword from "./Components/ResetPassword";
// import AllChatBots from "./Components/AllChatBots";
import Subscriptions from "./Components/Subscriptions";
import Footer from "./Components/Footer";
import Profile from "./Components/Profile";
import Stripepay from "./Components/Stripepay";
import Dashboard from "./Components/Dashboard";
import Chat from "./Components/Chat";
import Setting from "./Components/Setting";
import CreateChatbot from "./Components/CreateChatbot";
import CreateChatbot01 from "./Components/CreateChatBot01";
import CustomQA from "./Components/CustomQA";
import Conversation from "./Components/Conversation";
import FriendsChatbot from "./Components/FriendsChatbot";
import UpdateKnowledge from "./Components/UpdateKnowledge";
import Interface from "./Components/Interface";
// import Home from './Components/Home'
// import Insights from "./Components/Insights";
import FAQs from "./Components/FAQs";
import AdminDashboard from "./Admin-Panel/Admin-Components/AdminDashboard";
import UserData from './Admin-Panel/Admin-Components/Subscribers';
import CustomerData from './Admin-Panel/Admin-Components/ViewCustomer';
import AdminAffiliate from './Admin-Panel/Admin-Components/AdminAffiliate';
import AdminLogin from "./Admin-Panel/Admin-Components/AdminLogin";
import AdminConversation from "./Admin-Panel/Admin-Components/AdminConversation";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";
import FullScreenLoader from './Components/FullScreenLoader';
import Header from './Components/Header';
import AdminHeader from './Admin-Panel/Admin-Components/AdminHeader'
import EmailVerify from './Components/EmailVerify';


const Home = React.lazy(() => import('./Components/Home'));
const SignIn = React.lazy(() => import('./Components/SignIn'));
const SignUp = React.lazy(() => import('./Components/SignUp'));
const Pricing = React.lazy(() => import('./Components/Pricing'));
const AllChatBots = React.lazy(() => import('./Components/AllChatBots'));
const Insights = React.lazy(() => import('./Components/Insights'));

function App() {

  // Lazy load components with an artificial delay
  // const Home = React.lazy(() => {
  //   return new Promise((resolve) => {
  //     // Simulate a delay of 2-3 seconds before resolving the lazy component
  //     setTimeout(() => {
  //       resolve(import('./Components/Home'));
  //     }, 2000); // Adjust the delay time as per your preference (in milliseconds)
  //   });
  // });

  const isAdminRoute = (location) => {
    return (
      location.pathname.includes('/admin_dashboard') ||
      location.pathname.includes('/admin_subscriber') ||
      location.pathname.includes('/admin_customer') ||
      location.pathname.includes('/admin_affiliate') ||
      location.pathname.includes('/admin_conversation')
    );
  };

  const isAdminLogin = (location) => {
    return (
      location.pathname.includes('/admin_login')
    );
  };

  const [adminRouteActive, isAdminLoginActive] = useState(false)
  const [adminRoute, isAdminRouteActive] = useState(false)

  useEffect(() => {
    if (isAdminLogin(window.location)) {
      isAdminLoginActive(true)
    } else {
      isAdminLoginActive(false)
    }

    if (isAdminRoute(window.location)) {
      isAdminRouteActive(true)
    } else {
      isAdminRouteActive(false)
    }
    
  })

  // const HeaderSwitcher = ({ isAdminRoute }) => {
  //   return isAdminRoute ? <AdminHeader /> : <Header />;
  // };

  return (
    <div className="App">
      <BrowserRouter>
      {adminRouteActive === true ? (
        null
      ):(
        // <HeaderSwitcher isAdminRoute={isAdminRouteActive(window.location)} />
        <div>
          {adminRoute === true ? (
          <AdminHeader />
        ):(
          <Header />
        )}
        </div>
      )}
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Chatnext</title>
                  <meta name="Chatnext" content="Your default description" />
                </Helmet>
                <Suspense fallback={<FullScreenLoader />}>
                  <Home />
                </Suspense>
              </>
            }
          />
          <Route
            path="/sign_in"
            element={
              <>
                <Helmet>
                  <title>Sign In | Chatnext</title>
                  <meta name="Sign In" content="Click here to Sign in directly" />
                </Helmet>
                <Suspense fallback={<FullScreenLoader />}>
                  <SignIn />
                </Suspense>
              </>
            }
          />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/verify_account" element={<VerifyAccount />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route
            path="/sign_up"
            element={
              <>
                <Helmet>
                  <title>Sign Up | Chatnext</title>
                  <meta name="Sign Up" content="Click here to sign up on Chatnext" />
                </Helmet>
                <Suspense fallback={<FullScreenLoader />}>
                  <SignUp />
                </Suspense>
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <Helmet>
                  <title>Pricing | Chatnext</title>
                  <meta name="Pricing" content="See our prices for the chatbot" />
                </Helmet>
                <Suspense fallback={<FullScreenLoader />}>
                  <Pricing />
                </Suspense>
              </>
            }
          />
          <Route path="/my_profile" element={<Profile />} />
          <Route path="/Stripepay" element={<Stripepay />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/create_chatbot_options" element={<CreateChatbotOptions />} />
          <Route path="/create_chatbot" element={<CreateChatbot />} />
          <Route path="/create_chatbot01" element={<CreateChatbot01 />} />
          <Route exact path="/my_chatbots" element={<Suspense fallback={<FullScreenLoader />}><AllChatBots /></Suspense>} />
          <Route path="/my_chatbots/:chatbotId/chat" element={<Chat />} />
          <Route path="/my_chatbots/:chatbotId/settings" element={<Setting />} />
          <Route path="/my_chatbots/:chatbotId/update_knowledge" element={<UpdateKnowledge />} />
          <Route path="/my_chatbots/:chatbotId/custom_qa" element={<CustomQA />} />
          <Route path="/my_chatbots/:chatbotId/conversation" element={<Conversation />} />
          <Route path="/friends_chatbot" element={<FriendsChatbot />} />
          <Route path="/interface" element={<Interface />} />
          <Route path="/my_chatbots/:chatbotId/insights" element={<Suspense fallback={<FullScreenLoader />}><Insights /></Suspense>} />
          <Route
            path="/faqs"
            element={
              <>
                <Helmet>
                  <title>FAQs | Chatnext</title>
                  <meta name="FAQs" content="See the most frequently asked quetions by our uers" />
                </Helmet>
                <Suspense fallback={<FullScreenLoader />}>
                  <FAQs />
                </Suspense>
              </>
            }
          />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_of_use" element={<TermsOfUse />} />
          <Route path="/verify_email/:verificationCode" element={<EmailVerify />} />

          {/* Admin Pages */}
          <Route path="/admin_login" element={<AdminLogin />} />
          <Route path="/admin_dashboard" element={<AdminDashboard />} />
          <Route path="/admin_subscriber" element={<UserData />} />
          <Route path="/admin_customer" element={<CustomerData />} />
          <Route path="/admin_affiliate" element={<AdminAffiliate />} />
          <Route path="/admin_conversation" element={<AdminConversation />} />
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
