import React, { useState, useEffect } from "react";
import "../Css/SideBar.css";
import { Link, useLocation } from "react-router-dom";
import DashboardImg from "../Assests/images/dashboard_img.svg";
import ChatSvg2 from "../Assests/images/chat_svg3.svg";
import InsightSvg from "../Assests/images/insight_svg.svg";
import SettingSvg from "../Assests/images/setting_svg.svg";
import KnowledgeSvg from "../Assests/images/knowledge_svg.svg";
import Profile2 from "../Assests/images/profile_svg2.svg";
import ConversationSvg from "../Assests/images/conversation_svg.svg";
import axios from 'axios';
import { chatboat_by_id } from '../ServiceConnection/serviceconnection.js';
function SideBar(props) {
  const location = useLocation();
  const [start_for_chatbotiddata, setStartForChatbotiddata] = useState(""); 
  const [Chatbotnamevr, setChatbotnamevr] = useState(""); 

  useEffect(() => {
    const currentUrl = location.pathname;
        const pathnameParts = currentUrl.split('/');
        const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
       
        const headers = {
          "Content-Type": "application/json", // Change the content type to handle file upload
        };
        axios.get(chatboat_by_id(secondValue), headers)
          .then(function (response) {
            setStartForChatbotiddata(response.data.data.Chatbot_id);
            setChatbotnamevr(response.data.data.Name);
          })
          .catch(function (error) {
            console.error(error);
          });
  },[location.pathname]);

  return (
    <div className="sidebar">
      <div className="sidebar_wrapper">
        <div className="sidebar_container">
          <div className="sidebar_head">
            <div className="head_img_div">
              <img src={DashboardImg} alt="icon" />
            </div>
            <div className="head_text_div">
              <h4>{Chatbotnamevr}</h4>
              <p>GPT-3.5-turbo</p>
            </div>
          </div>
          <div className="sidebar_body">
            <div className="sidebar_body_container">
              <ul>
                <li
                  className={location.pathname === `/my_chatbots/${start_for_chatbotiddata}/insights` ? "active" : ""}
                >
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/insights`}>
                    <div className="list_item">
                      <img src={InsightSvg} alt="icon" />
                      <p>Insight</p>
                    </div>
                  </Link>
                </li>
                <li className={location.pathname === `/my_chatbots/${start_for_chatbotiddata}/chat` ? "active" : ""}>
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/chat`} chatbotdata={start_for_chatbotiddata}>
                    <div className="list_item">
                      <img src={ChatSvg2} alt="icon" />
                      <p>Chat</p>
                    </div>
                  </Link>
                </li>
                <li
                  className={location.pathname === `/my_chatbots/${start_for_chatbotiddata}/settings` ? "active" : ""}
                >
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/settings`}>
                    <div className="list_item">
                      <img src={SettingSvg} alt="icon" />
                      <p>Setting</p>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === `/my_chatbots/${start_for_chatbotiddata}/update_knowledge` ? "active" : ""
                  }
                >
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/update_knowledge`}>
                    <div className="list_item">
                      <img src={KnowledgeSvg} alt="icon" />
                      <p>Update Knowledge</p>
                    </div>
                  </Link>
                </li>
                {/*
                <li
                  className={location.pathname === `/my_chatbots/${start_for_chatbotiddata}/custom_qa` ? "active" : ""}
                >
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/custom_qa`}>
                    <div className="list_item">
                      <img src={Profile2} alt="icon" />
                      <p>Custom Q & A</p>
                    </div>
                  </Link>
                </li>
                */}
                <li
                  className={
                    location.pathname === `/my_chatbots/${start_for_chatbotiddata}/conversation` ? "active" : ""
                  }
                >
                  <Link to={`/my_chatbots/${start_for_chatbotiddata}/conversation`}>
                    <div className="list_item">
                      <img src={ConversationSvg} alt="icon" />
                      <p>Conversation</p>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
