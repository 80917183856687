import React, { useState, useEffect } from "react";
import "../Css/Interface.css";
import { Modal } from "react-bootstrap";
// import HelloIcon from "../Assests/images/hellow_icon.png";
import Profile3 from "../Assests/images/profile_3.svg";
// import DeleteSvg from "../Assests/images/delete2_svg.svg";
import Success from '../Assests/images/success_gif.gif'
import $ from 'jquery';
import axios from 'axios';
import { appauth_key, chatboat_setting_update,api_link } from '../ServiceConnection/serviceconnection.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Interface({ chatbotdata }) {
  // const [inputFields, setInputFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [headings, SetHeadings] = useState('Welcome');
  const [subheadings, SetSubHeadings] = useState('Ask me anything');
  const [logoimg, SetLogoImg] = useState(Profile3);
  const [logoimgfile, SetLogoImgFile] = useState(Profile3);
  const [logoimgfile1, SetLogoImgFile1] = useState(0);
  const [headingtext, SetHeadingText] = useState('#ffffff');
  const [headingbackground, SetHeadingBackground] = useState('#000');
  const [linksuggetion, SetLinkSuggetion] = useState('Learn more');
  const [placeholder, SetPlaceholder] = useState('Type Something...');
  const [firstmsg, SetFirstMsg] = useState('Hello! What can I do for you today?');
  const [usermsgtext, SetUserMsgText] = useState('#ffffff');
  const [usermsgbackground, SetUserMsgBackground] = useState('#800080');
  const [botmsgtext, SetBotMsgText] = useState('#ffffff');
  const [botmsgbackground, SetBotMsgBackground] = useState('#800080');
  const [chatbubbleimage, SetChatBubbleImage] = useState('#800080');
  const [chatbubblebackground, SetChatBubbleBackground] = useState('#EEF1F3');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    SetHeadings(chatbotdata.Heading);
    SetSubHeadings(chatbotdata.SubHeading);
    SetHeadingText(chatbotdata.Text);
    SetHeadingBackground(chatbotdata.Background);
    SetPlaceholder(chatbotdata.Input_Placeholder);
    SetFirstMsg(chatbotdata.First_Message);
    SetUserMsgText(chatbotdata.User_Message_text);
    SetUserMsgBackground(chatbotdata.User_Message_background);
    SetBotMsgText(chatbotdata.Bot_Message_text);
    SetBotMsgBackground(chatbotdata.Bot_Message_background);
    SetChatBubbleImage(chatbotdata.Chat_Bubble_Image);
    SetChatBubbleBackground(chatbotdata.Chat_Bubble_Background);
    if(chatbotdata.Image!=null){
    SetLogoImg(api_link+chatbotdata.Image || Profile3);
    }else{
      SetLogoImg(Profile3); 
    }
  }, [chatbotdata])

  const handleLogoImg = (event) => {
    const file = event.target.files[0]; // Get the selected file
    const files = event.target.files; // Get the selected file

    // Create a FileReader object to read the file contents
    const reader = new FileReader();

    // Define a callback function to handle the file reading
    reader.onload = (e) => {
      const imageDataUrl = e.target.result; // Get the image data URL
      SetLogoImg(imageDataUrl); // Update the state with the image data URL
      SetLogoImgFile(file); // Update the state with the image data URL
      SetLogoImgFile1(1); 

    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  };

  // const handleInputChange = (e, index) => {
  //   const values = [...inputFields];
  //   values[index] = e.target.value;
  //   setInputFields(values);
  // };

  // const handleRemoveField = (index) => {
  //   const values = [...inputFields];
  //   values.splice(index, 1);
  //   setInputFields(values);
  // };

  // Error Message
  function showErrorMsg() {
    toast.error('Error in updating', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  function showSuccessMsg() {

    toast.success('Updated Succesfully!', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  const validateForm = () => {
    var isValid = true;
    if (isValid === true) {
      if (isValid === true) {
        const formData = new FormData(document.getElementById('save_form')); // Get form data
        formData.append('appauth_key', appauth_key);
        formData.append('Chatbot_id', chatbotdata.chatbot_id);
        if(logoimgfile1){
        formData.append('Image', logoimgfile);
        }
        saving_data(formData); // Pass the form data to the saving_data function
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };





  const saving_data = async (jsonData) => {
    
    setLoading(true);
    axios.patch(chatboat_setting_update, jsonData)
      .then(function (response) {
        setLoading(false);
        if (response.data.status != 'success') {
          showErrorMsg(response.data.message);
        } else {
          showSuccessMsg();
        }
      }).catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };
  return (
    <div className="interface">
      <div className="interface_wrapper">
        <div className="interface_container">
          <h3>Interface</h3>
          <form enctype="multipart/form-data" id='save_form'>
            <div className="interface_setting_div">

              <div className="row">
                <div className="col-4">
                  <div className="left_settings">
                    <div className="header_settings">
                      <div>
                        <h6>Header</h6>
                        <div className="setting_flex">
                          <p>Heading:</p>
                          <div>
                            <input className="chat_page_input" type="text" style={{ height: '38px' }} name='Heading' value={headings}
                              onChange={(e) => SetHeadings(e.target.value)} />
                          </div>
                        </div>
                        <div className="setting_flex">
                          <p>SubHeading:</p>
                          <div>
                            <input className="chat_page_input" type="text" style={{ height: '38px' }} name='SubHeading'
                              value={subheadings}
                              onChange={(e) => SetSubHeadings(e.target.value)} />
                          </div>
                        </div>
                        <div className="setting_flex">
                          <p>Image</p>
                          <div>
                            <input
                              id="image_upload" name='Image2'
                              type="file"
                              className="d-none" onChange={handleLogoImg}
                            />
                            <label htmlFor="image_upload">Upload image <br /><span className="input_size">(45 x 45 pixels)<sup style={{ color: 'red' }}>*</sup></span></label>
                          </div>
                        </div>
                        <div className="setting_flex">
                          <p>Text</p>
                          <div className="setting_flex m-0">
                            <input
                              className="color_input" name='Text'
                              value={headingtext}
                              onChange={(e) => SetHeadingText(e.target.value)}
                              type="color"
                            />
                          </div>
                        </div>
                        <div className="setting_flex">
                          <p>Background</p>
                          <div className="setting_flex m-0">
                            <input
                              className="color_input" name='Background'
                              value={headingbackground}
                              onChange={(e) => SetHeadingBackground(e.target.value)}
                              type="color"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="link_suggestion_setting mt-5" style={{opacity:'0'}}>
                      <div className="link_label">
                        <h6 className="text-left">Link Suggestions Label</h6>
                      </div>
                      <input className="w-100 mb-3 chat_page_input" type="text" style={{ height: '40px' }} name='linksuggetion' value={linksuggetion}
                        onChange={(e) => SetLinkSuggetion(e.target.value)} />
                    </div>
                    <div className="input_placeholder_setting link_suggestion_setting">
                      <div className="link_label">
                        <h6 className="text-left">Input Placeholder</h6>
                      </div>
                      <input className="w-100 chat_page_input" style={{ height: '40px', marginBottom: '1rem' }} type="text" name='Input_Placeholder' value={placeholder}
                        onChange={(e) => SetPlaceholder(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 mx-auto my-0">
                  <div className="chat_page">
                    <div>
                      <div className="chat_page_head shadow" style={{ 'background-color': headingbackground }}>
                        <div className="welcome_heading">
                          <img src={logoimg} alt="icon" />
                          <div className="welcome_text_div">
                            <h4 style={{ 'color': headingtext }}>{headings}</h4>
                            <p style={{ 'color': headingtext }}>{subheadings}</p>
                          </div>
                        </div>
                      </div>
                      <div className="msg_div">
                        <div className="received_msg" style={{ 'color': botmsgtext, 'background-color': botmsgbackground }}>
                          <p>{firstmsg}</p>
                        </div>
                        <div className="sent_msg" style={{ 'color': usermsgtext, 'background-color': usermsgbackground }}>
                          <p>Hello! What can I do for you today?</p>
                        </div>
                        <div className="received_msg" style={{ 'color': botmsgtext, 'background-color': botmsgbackground }}>
                          <p>
                            Please go to "Settings" of the chatbot, and choose
                            "Add to website" and add the given script to your
                            website html.
                          </p>
                        </div>
                      </div>
                      {/* <div className="learn_more_chat">
                        <p
                          className="learn_more_btn"
                          data-toggle="collapse"
                          data-target="#collapseExample"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          {linksuggetion}{" "}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="currentColor"
                              className="bi bi-chevron-down"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                          </span>
                        </p>
                        <div className="collapse" id="collapseExample">
                          <div className="collapse_body">
                            <p>/Some urls here</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="send_msg_input_div">
                      {/* <div className="suggested_msgs">
                        <div className="suggested_msgs_flex" >
                          <div style={{ 'color': botmsgtext, 'background-color': botmsgbackground }}>
                            <p>how are you</p>
                          </div>
                          <div style={{ 'color': botmsgtext, 'background-color': botmsgbackground }}>
                            <p>hello</p>
                          </div>
                        </div>
                      </div> */}
                      <div className="send_msg_input">
                        <input type="text" className="placeholder_elepsis" placeholder={placeholder} />
                        <div className="btn chat_send_btn">
                          {/* <img src={SendSvg} alt='icon' /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="#000"
                            className="bi bi-send"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                          </svg>
                        </div>
                      </div>
                      <div className="branding_div_chat">
                        <p>Powered by Chatboat</p>
                      </div>
                    </div>
                    <div className="header_pointer"></div>
                    <div className="first_msg_pointer"></div>
                    {/* <div className="link_suggestion_pointer"></div> */}
                    <div className="user_msg_pointer"></div>
                    <div className="placeholder_pointer"></div>
                    <div className="bot_msg_pointer"></div>
                    {/* <div className="suggested_msg_pointer"></div> */}
                  </div>
                </div>
                <div className="col-4">
                  <div className="right_settings">
                    <div className="first_msg_setting link_suggestion_setting mt-0">
                      <div className="link_label">
                        <h6 className="text-left">First Message</h6>
                      </div>
                      <input className="w-100 mb-3 chat_page_input" type="text" style={{ height: '40px' }} name='First_Message' value={firstmsg} onChange={(e) => SetFirstMsg(e.target.value)} />
                    </div>
                    <div className="user_msg_setting link_suggestion_setting">
                      <div className="link_label">
                        <h6 className="text-left">User Message</h6>
                      </div>
                      <div className="user_msg_color_div mt-4 mb-3">
                        <div className="user_msg_color">
                          <p>Text</p>
                          <input
                            className="color_input" name='User_Message_text'
                            value={usermsgtext}
                            onChange={(e) => SetUserMsgText(e.target.value)}

                            type="color"
                          />
                        </div>
                        <div className="user_msg_color">
                          <p>Background</p>
                          <input
                            className="color_input" name='User_Message_background'
                            value={usermsgbackground}
                            onChange={(e) => SetUserMsgBackground(e.target.value)}

                            type="color"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="user_msg_setting link_suggestion_setting">
                      <div className="link_label">
                        <h6 className="text-left">Bot Message</h6>
                      </div>
                      <div className="user_msg_color_div mt-4 mb-3">
                        <div className="user_msg_color">
                          <p>Text</p>
                          <input
                            className="color_input" name='Bot_Message_text'
                            value={botmsgtext}
                            onChange={(e) => SetBotMsgText(e.target.value)}

                            type="color"
                          />
                        </div>
                        <div className="user_msg_color">
                          <p>Background</p>
                          <input
                            className="color_input" name='Bot_Message_background'
                            value={botmsgbackground}
                            onChange={(e) => SetBotMsgBackground(e.target.value)}

                            type="color"
                          />
                        </div>
                      </div>
                    </div>
                    {/*
                  <div className="suggested_msg_setting link_suggestion_setting">
                    <div className="suggested_msg_setting_head">
                      <h6>Suggested Message</h6>
                      <p onClick={handleAddField}>+</p>
                    </div>
                    <div>
                      <div className="suggestion_input_div">
                        <input
                          className="suggestion_input"
                          type="text"
                          value="hello"
                        />
                        <img
                          className="suggestion_input_dlt"
                          src={DeleteSvg}
                          alt="icon"
                        />
                      </div>
                      <div className="suggestion_input_div">
                        <input
                          className="suggestion_input"
                          type="text"
                          value="how are you"
                        />
                        <img
                          className="suggestion_input_dlt"
                          src={DeleteSvg}
                          alt="icon"
                        />
                      </div>
                      {inputFields.map((value, index) => (
                        <div key={index} className="suggestion_input_div">
                          <input
                            className="suggestion_input"
                            key={index}
                            type="text"
                            value={value}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <img
                            className="suggestion_input_dlt"
                            src={DeleteSvg}
                            alt="icon"
                            onClick={() => handleRemoveField(index)}
                          />
                        </div>
                      ))}
                    </div>
                      </div>*/}
                  </div>
                </div>
              </div>

              <div className="floating_icon_setting mt-5">
                <div className="floating_icon_div">
                  <div>
                    <div className="floating_icon" style={{ backgroundColor: chatbubblebackground }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="26"
                        fill={chatbubbleimage}
                        className="bi bi-chat-dots"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-4 w-100 pr-0">
                    <div className="chat_bubble link_suggestion_setting">
                      <div className="link_label">
                        <div className="suggested_msg_setting_head">
                          <h6>Chat Bubble</h6>
                        </div>
                      </div>
                      <div className="user_msg_color_div">
                        <div className="user_msg_color">
                          <p>Image</p>
                          <input
                            className="color_input" name='Chat_Bubble_Image'
                            value={chatbubbleimage}
                            onChange={(e) => SetChatBubbleImage(e.target.value)}

                            type="color"
                          />
                        </div>
                        <div className="user_msg_color">
                          <p>Background</p>
                          <input
                            className="color_input" name='Chat_Bubble_Background'
                            value={chatbubblebackground}
                            onChange={(e) => SetChatBubbleBackground(e.target.value)}

                            type="color"
                          />
                        </div>
                      </div>
                      <div className="chat_bubble_pointer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
              <div className="interface_update_btn_div">
                <div className='btn interface_update_btn' onClick={validateForm}>
                  Update
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
      <Modal id='myModal' show={showModal} onHide={closeModal} centered >
        <Modal.Body className='modal_body'>
          <div className='success_img d-flex justify-content-center'>
            <img src={Success} alt='icon' />
          </div>
          <p>Successfully Save.</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn go_to_login' onClick={closeModal}>Ok</div>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Interface;