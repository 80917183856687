import React, { useState } from 'react'
import '../Admin-Css/AdminLogin.css'
import { useNavigate } from 'react-router-dom';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import $ from 'jquery';
import axios from 'axios';
import Cookies from 'js-cookie';
import { admin_sign_in } from '../../ServiceConnection/serviceconnection.js';

function AdminLogin() {
    const [isLoading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [user, setUser] = useState([]);
    const navigatesignin = useNavigate();

        const validateForm = () => {
            var isValid = true;
        
            $(".trio_mandatory").each(function () {
              const $inputfld = $(this);
              const value = $.trim($inputfld.val());
              if (value === "") {
                isValid = false;
                $inputfld.css({ border: "1px solid red", width: "50px !important" });
                if ($inputfld.next("span.err_msg").length === 0) {
                  $("<span>").addClass("err_msg").text("Please fill this field").insertAfter($inputfld);
                }
              } else {
                $inputfld.css({ border: "", background: "" });
                $inputfld.next("span.err_msg").remove();
              }
            });
        
            if (isValid === true) {
              $('span.err_msg').text('');
              let email_id = document.querySelector('.email_id').value;
              let password = document.querySelector('.password').value;
        
              if (!validateEmail(email_id)) {
                isValid = false;
                adderrorspan('email_id', 'Email Id is not valid');
              }
              
              if (password.length < 8) {
                isValid = false;
                adderrorspan('password', 'Password should be at least 8 characters');
              }
        
        
        
        
              if (isValid === true) {
                const formData = new FormData(document.getElementById('save_form')); // Get form data
                const jsonData = {};
        
                for (let [key, value] of formData.entries()) {
                  jsonData[key] = value;
                }
        
        
                saving_data(jsonData); // Pass the form data to the saving_data function
              }
        
        
            }
          };
        
        
          const saving_data = async (jsonData) => {
        
            const headers = {
              "Content-Type": "application/json", // Change the content type to handle file upload
            };
            setLoading(true);
        
            axios.post(admin_sign_in, jsonData, headers)
              .then(function (response) {
                setLoading(false);
                if (response.data.status !== 'success') {
                  $('.server_error').text(response.data.msg);
                } else {
                  // Session Storage
                  session_func(response)
                }
              }).catch(function (error) {
                console.error(error);
                $('.server_error').text(error.message);
                // Handle the error here
                setLoading(false);
                // You can display an error message or perform any necessary actions
              });
        
        
          };
        
          const session_func = (response) => {
            localStorage.clear();
            console.log(response);
            localStorage.setItem('admin_user_id', response.data.data.id);
            localStorage.setItem('admin_name', response.data.data.user_name);
            localStorage.setItem('admin_email', response.data.data.email);
            Cookies.set('access_token', response.data.data.token.access_token);
            Cookies.set('refresh_token', response.data.data.token.refresh_token);
            // navigatesignin('/admin_dashboard');
            window.location.href = '/admin_dashboard';
        
        
          };
          const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
          };
        
          const togglePasswordVisibility = () => {
            setPasswordVisible(!passwordVisible);
          };
          const adderrorspan = (clasnm, msg) => {
            $("<span>").addClass("err_msg").text(msg).insertAfter('.' + clasnm);
            $('.' + clasnm).css({ border: "1px solid red", width: "50px !important" });
          };
        
    return (
        <div className='admin_login'>
            <div className='admin_login_wrapper'>
                <div className='col-xl-3 col-lg-4 col-md-5 col-sm-7 col-11'>
                    <div className='admin_login_container shadow'>
                        <form id='save_form'>
                            <h2 className='text-center'><span style={{color:'#800080'}}>Admin</span><span style={{color:'#FC9E4C'}}> LogIn</span></h2>
                            {/* <div className='login_with_email text-center'>
                                <p>LOG IN WITH USERNAME AND PASSWORD</p>
                            </div> */}
                            <div className='input_fields'>
                                <div className='input_div_signin'>
                                    <input
                                        type='email'
                                        placeholder='Username'
                                        name='email'
                                        className='email_id trio_mandatory'
                                    />
                                </div>
                                <br />
                                <div className='password_input input_div_signin'>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder='Password'
                                        name='password'
                                        className='password trio_mandatory'
                                    />
                                    <span
                                        className='password_toggle_icon'
                                        onClick={togglePasswordVisibility}
                                    >
                                        {passwordVisible ? <RiEyeOffFill /> : <RiEyeFill />}
                                    </span>
                                </div>
                            </div>
                            {/* <div className='forgot_remember_div'>
                                <div>
                                    <Link to='/forgot_password'>Forget password</Link>
                                </div>
                            </div> */}

                            <span className='server_error err_msg'></span>
                            <div className='login_btn_div text-center'>
                                <div className='btn login_btn' onClick={validateForm}>
                                    Log in
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin