import React, { useState } from 'react';
import '../Css/ForgotPassword.css';
import { Link,useNavigate } from 'react-router-dom';
import { Modal} from 'react-bootstrap';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordReset from '../Assests/images/password_reset.gif';
import Success from '../Assests/images/success_gif.gif';
import axios from 'axios';
import { change_password } from '../ServiceConnection/serviceconnection.js';
import Header from './Header';
import $ from 'jquery';
function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleResetPassword = () => {
        if (password === '') {
            setPasswordError('Please enter a password');
        } else if (password.length < 8) {
            setPasswordError('Password should be at least 8 characters long');
        } else if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setLoading(true);
            const headers = {
                "Content-Type": "application/json", // Change the content type to handle file upload
            };
            const jsonData = {};
            jsonData['email'] = localStorage.getItem('forgot_email');
            jsonData['password'] = password;
            jsonData['re_password'] = confirmPassword;
            axios.patch(change_password,jsonData, headers)
                .then(function (response) {
                    setLoading(false);
                    console.log(response);
                    if(response.data.status!=='success'){
                      $('.server_error').text(response.data.msg);
                    }else{ 
                    // Session Storage
                      //localStorage.setItem('forgot_email',email);
                      setShowModal(true);
                    }
                }).catch(function (error) {
                    console.error(error);
                    $('.server_error').text(error.message);
                    // Handle the error here
                    setLoading(false);
                    // You can display an error message or perform any necessary actions
                  });
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/sign_in');
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    return (
        <div className='forgot_password'>
            {/* <div>
                <Header />
            </div> */}
            <div className='forgot_password_wrapper'>
                <div className='forgot_password_container row col-xl-6 col-lg-7 col-md-9 col-sm-10 shadow m-auto p-0'>
                    <div className='left_section col-md-4'>
                        <div className='forgot_img_div'>
                            <img src={PasswordReset} alt='chatbot' />
                        </div>
                    </div>
                    <div className='right_section col-md-7 m-auto'>
                        <div className='form_div col-md-9 col-10 m-auto'>
                            <h2 className='text-center'>Reset Password</h2>
                            <p>Enter your new password, and it should not match with the previous passwords.</p>
                            <div className='input_fields'>
                                <div className='otp_input'>
                                    <div className='password_input input_div_signin'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Password'
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                        {showPassword ? (
                                            <RiEyeOffFill
                                                className='password_icon password_toggle_icon'
                                                onClick={togglePasswordVisibility}
                                            />
                                        ) : (
                                            <RiEyeFill
                                                className='password_icon password_toggle_icon'
                                                onClick={togglePasswordVisibility}
                                            />
                                        )}
                                    </div>
                                    {/* <br /> */}
                                    <div className='password_input input_div_signin'>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Confirm password'
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                        />
                                        {showConfirmPassword ? (
                                            <RiEyeOffFill
                                                className='password_icon password_toggle_icon'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        ) : (
                                            <RiEyeFill
                                                className='password_icon password_toggle_icon'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        )}
                                    </div>
                                    {passwordError && <p className='error err_msg'>{passwordError}</p>}
                                </div>
                            </div>
                            <div className='otp_btn_div text-center'>
                                <div className={`btn otp_btn`} onClick={handleResetPassword}>
                                    Reset Password
                                </div>
                            </div>
                            <div className='back_btn'>
                                <Link to='/forgot_password'>
                                    <span>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='16'
                                            height='16'
                                            fill='#F58634'
                                            className='bi bi-arrow-left-circle-fill'
                                            viewBox='0 0 16 16'
                                        >
                                            <path
                                                d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'
                                            />
                                        </svg>
                                    </span>
                                    <span className='goback_text'>Go back</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
            <Modal show={showModal} onHide={handleCloseModal} centered backdrop="static">
                <Modal.Body className='modal_body'>
                    <div className='success_img d-flex justify-content-center'>
                        <img src={Success} alt='chatbot' />
                    </div>
                    <p>Your password has been successfully changed. Click on the button below to log in.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Link to='/sign_in'>
                        <div className='btn go_to_login'>Go to login</div>
                    </Link>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ResetPassword;
