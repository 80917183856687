import MUIDataTable from "mui-datatables";
import '../Admin-Css/AdminAffiliate.css'
import '../Admin-Css/Subscribers.css'
import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TextField } from "@mui/material";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

function AdminAffiliate() {
    // const [responsive, setResponsive] = useState("vertical");
    // const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    // const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    // const [searchBtn, setSearchBtn] = useState(true);
    // const [downloadBtn, setDownloadBtn] = useState(true);
    // const [printBtn, setPrintBtn] = useState(true);
    // const [viewColumnBtn, setViewColumnBtn] = useState(true);
    // const [filterBtn, setFilterBtn] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const columns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Joined Date & Time"
    ];

    const options = {
        search: true,
        download: false, // Disable the default download button in MUI DataTable
        print: true,
        customToolbar: () => (
            // Custom toolbar component with Excel download button
            <button className="custom-excel-button" onClick={handleExcelDownload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="#686b6b" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                    <path d="M6 12v-2h3v2H6z" />
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                </svg>
            </button>
        ),
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        onTableChange: (action, state) => {
            console.dir(state);
        },
        customFilterDialogFooter: () => {
            return (
                <div>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
            );
        },
        customFilterFunction: (value, dataIndex, columnMeta, filterData) => {
            const startDateFilter = new Date(startDate);
            const endDateFilter = new Date(endDate);

            const dateValue = new Date(value);

            if (startDateFilter && endDateFilter) {
                return dateValue >= startDateFilter && dateValue <= endDateFilter;
            }

            return true;
        }
    };

    const handleExcelDownload = () => {
        // Prepare the data for the Excel file
        const excelData = [
            columns.map(column => column.name),
            ...data
        ];

        // Create a new workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Convert the workbook to an Excel file buffer
        const excelBuffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

        // Save the Excel file
        const excelFile = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(excelFile, "ACME_Employee_List.xlsx");
    };

    const button = <div className="active_disable_buttons">
        <p>22/06/2023</p>
        <p>4:48 PM</p>
    </div>

    const data = [
        ["Gabby George", "Free Trail", "XYZ@gmail.com", button],
        [
            "Aiden Lloyd",
            "Dallas", "XYZ@gmail.com", button
        ],
        ["Jaden Collins", "Santa Ana", "XYZ@gmail.com", button],
        ["Franky Rees", "St. Petersburg", "XYZ@gmail.com", button],
        ["Aaren Rose", "Toledo", "XYZ@gmail.com", button],
        ["Johnny Jones", "St. Petersburg", "XYZ@gmail.com", button],
        ["Jimmy Johns", "Baltimore", "XYZ@gmail.com", button],
        ["Jack Jackson", "El Paso", "XYZ@gmail.com", button],
        ["Joe Jones", "El Paso", "XYZ@gmail.com", button],
        ["Jacky Jackson", "Baltimore", "XYZ@gmail.com", button],
        ["Jo Jo", "Washington DC", "XYZ@gmail.com", button],
        ["Donna Marie", "Annapolis", "XYZ@gmail.com", button]
    ];

    const mappedData = data.map((item) => item.map((value) => value));

    return (
        <div className='admin_affiliate admin_dashboard'>
            {/* <div>
                <AdminHeader />
            </div> */}
            <div className='admin_affiliate_wrapper admin_dashboard_wrapper'>
                <div className='admin_affiliate_container admin_dashboard_container'>
                    <div>
                        <AdminSidebar />
                    </div>
                    <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem" }}>
                        <div className="admin_subscriber_head custom_qa_head">
                            <div
                                className="btn sidebar_toggle_btn d-block d-lg-none"
                                id="sidebar_toggle_btn"
                            >
                                <div className="rotate_arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="#800080"
                                        className="bi bi-chevron-double-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="user_data_table_container">
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={createTheme()}>
                                    <MUIDataTable
                                        title={"Affiliates"}
                                        data={mappedData}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminAffiliate;
