import React, { useEffect, useState, useCallback } from "react";
import '../Css/Pricing.css'
import Header from './Header';
import Footer from './Footer';
import { Link, useLocation, useNavigate } from "react-router-dom";
import QueryString from "query-string";
import axios from "axios";
import { api_link} from '../ServiceConnection/serviceconnection.js';
function Stripepay() {
    const [btntext, Setbtntext] = useState("Sign Up");
    const [activeplan, Setactiveplan] = useState("");
    const [plans, setPlans] = useState([]);
    const location = useLocation();

    const [message, setMessage] = useState("");

    useEffect(() => {
        const values = QueryString.parse(location.search);
        //const query = new URLSearchParams(window.location.search);
        console.log(values);
        if (values.success) {
        console.log("Order placed! You will receive an email confirmation.");
        }

        if (values.canceled) {
            console.log(
            "Order canceled -- continue to shop around and checkout when you're ready."
        );
        }
        if (!values.success && !values.canceled) {
            console.log("Neither success nor canceled show plans");
          } 
    }, []);


    return (
        <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
      <h3>Stubborn Attachments</h3>
      <h5>$20.00</h5>
      </div>
    </div>
    <form action={`${api_link}stripe/create-checkout-session`} method="POST">
      <button type="submit">
        Checkout
      </button>
    </form>
  </section>
    );
}

export default Stripepay;