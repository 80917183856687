import React from 'react'
import { css } from '@emotion/react';
import { BeatLoader  } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function FullScreenLoader() {
    return (
        <div
            style={{
                // position: 'fixed',
                // top: 90,
                // left: 0,
                width: '100%',
                height: '80vh',
                // background: 'rgba(255, 255, 255)',
                background: 'transparent',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <BeatLoader color={'#800D80'} css={override} size={20} />
        </div>
    )
}

export default FullScreenLoader