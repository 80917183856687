import React, { useState }  from 'react';
import DeleteSvg from '../Assests/images/delete_svg.svg';
import { Modal, Button } from "react-bootstrap";
import $ from 'jquery';
import axios from 'axios';
import { appauth_key,chatboat_api_save } from '../ServiceConnection/serviceconnection.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate} from 'react-router-dom';
function DeletePage({chatbotdata}) {
    const [showModal, setShowModal] = useState(false);
    // const [showModalSave, setShowModalSave] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleModalOpen = () => {
        setShowModal(true);
      };
    
      const handleModalClose = () => {
        setShowModal(false);
        setShowErrorModal(false);
      };


      
  // Error Message
  function showErrorMsg() {
    toast.error('Error in updating', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  function showSuccessMsg() {
 
    toast.success('Updated Succesfully!', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }



  
    

  

  const deletechatbot = () => {
    setLoading(true);
    setShowModal(false);
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    setLoading(true);
    const jsonData = {};
    jsonData["chatbot_id"] = chatbotdata.chatbot_id;
    axios.delete(chatboat_api_save, {
      headers: headers,
      data: jsonData
    }).then(function (response) {
      setLoading(false);
        if(response.data.status!='success'){
          //$('.server_error').text(response.message);
          showErrorMsg();
        } else {
          showSuccessMsg();
          const chatboat_created = parseInt(localStorage.getItem('chatboat_created')) - parseInt(1);
          localStorage.setItem('chatboat_created',chatboat_created);
          navigate('/my_chatbots');
        }
      }).catch(function (error) {
        console.log(error);
        setLoading(false);
        showErrorMsg();
      });


  };

      
  return (
    <div className='delete_page'>
        <div className='delete_page_wrapper notification_wrapper'>
            <div className='delete_page_container notification_container'>
                <h5>Delete</h5>
                <div className='delete_chatbot_btn_div'>
                    <button className='delete_chatbot_btn' onClick={handleModalOpen}>
                        <img src={DeleteSvg} alt='icon' />
                        <p>Delete Chatbot</p>
                    </button>
                </div>
            </div>
        </div>
        <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
              <ToastContainer />
              <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
          <Modal.Title style={{ paddingLeft: "1rem" }}>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            Are you sure you want to remove this data source?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            className="confirm_btn"
            variant="primary"
            onClick={deletechatbot}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeletePage