import React, { useState, useEffect } from "react";
import '../Css/Notification.css'
import $ from 'jquery';
import axios from 'axios';
// import { post_api } from '../ServiceConnection/serviceconnection.js';
import { appauth_key, chatboat_setting_update } from '../ServiceConnection/serviceconnection.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Notification({ chatbotdata }) {
  const [isLoading, setLoading] = useState(false);

  const [notifications, SetNotifications] = useState(false);
  const [notificationsemail, SetNotificationsEmail] = useState('');
  const [details, SetDetails] = useState({
    Chat_Transcript: true,
    New_Contact_Information: false,
  });

  useEffect(() => {
    SetNotifications(chatbotdata.notifications);
    SetNotificationsEmail(chatbotdata.Email_Id);
    SetDetails((prevRecord) => ({
      ...prevRecord,
      Chat_Transcript: chatbotdata.Chat_Transcript,
      New_Contact_Information: chatbotdata.New_Contact_Information,
    }));
  }, [chatbotdata]);

  const handleCheckboxToogle = (e) => {
    const { checked } = e.target;
    SetNotifications(checked);
    if (!checked) {
      SetNotifications(false)
      SetDetails({
        Chat_Transcript: false,
        New_Contact_Information: false,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    SetDetails((prevRecord) => ({
      ...prevRecord,
      [name]: checked,
    }));
  };


  // Error Message
  function showErrorMsg() {
    toast.error('Error in updating', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }

  // Success Message
  function showSuccessMsg() {

    toast.success('Updated Succesfully!', {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  }


  const validateForm = () => {
    var isValid = true;
    if (isValid === true) {
      if (isValid === true) {
        const formData = new FormData(document.getElementById('save_form')); // Get form data
        formData.append('appauth_key', appauth_key);
        const jsonData = {};
        for (let [key, value] of formData.entries()) {
          //if(filesing)

          jsonData[key] = value;
        }
        jsonData['Chat_Transcript'] = details.Chat_Transcript;
        jsonData['New_Contact_Information'] = details.New_Contact_Information;
        jsonData['Email_Notification'] = notifications;
        jsonData['Chatbot_id'] = chatbotdata.chatbot_id;
        saving_data(jsonData); // Pass the form data to the saving_data function
        console.log(jsonData);
      }
    }
  };


  const saving_data = async (jsonData) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    setLoading(true);
    axios.patch(chatboat_setting_update, jsonData, headers)
      .then(function (response) {
        setLoading(false);
        if (response.data.status != 'success') {
          showErrorMsg(response.data.message);
        } else {
          showSuccessMsg();
        }
      }).catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };

  return (
    <div className="notification">
      <div className="notification_wrapper">
        <div className="notification_container col-lg-10">
          <h5>Notification</h5>
          <form id="save_form">
            <div className="toggle_btn_div">
              <label className="label_style nowrap_text1">Embed on website</label>
              <label className="switch">
                <input type="checkbox" name="Email_Notification"
                  checked={notifications}
                  onChange={handleCheckboxToogle} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className={notifications ? 'email_input' : 'email_input notifications_email_input_disabled'}>
              <input type="email" name="Email_Id" placeholder="XYZ @gmail.com" value={notificationsemail} onChange={(e) => SetNotificationsEmail(e.target.value)}></input>
            </div>
            <div className="checkbox_input_div">
              <input className={!notifications ? 'radio_lable_disabled' : ''} type="checkbox" name='Chat_Transcript' onChange={handleCheckboxChange}
                checked={details.Chat_Transcript} />
              <label htmlFor="vehicle1" className={notifications ? 'radio_lable' : 'radio_lable radio_lable_disabled'}  >
                Chat Transcript
              </label>
              <input className={!notifications ? 'radio_lable_disabled' : ''} type="checkbox" name='New_Contact_Information' onChange={handleCheckboxChange}
                checked={details.New_Contact_Information} />
              <label className={notifications ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="vehicle2" >
                New Contact information
              </label>
            </div>

          </form>
          <div className="update_btn_div">
            <button className="btn update_btn" onClick={validateForm}>Update</button>
          </div>
        </div>
      </div>
      <div
        className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
      >
        <div className='loader'></div>
      </div>
      <ToastContainer />

    </div>
  );
}

export default Notification;
