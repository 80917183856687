import React from 'react';
import '../Css/CreateByText.css'

function Text() {
    return (
        <div className='text_input'>
            <textarea placeholder='Piece of information, can be company info, book content...'></textarea>
        </div>
    );
}

export default Text;