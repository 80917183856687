import React, { useState,useEffect} from 'react';
import '../Css/Pricing.css'
import { Link } from 'react-router-dom';
// import $ from 'jquery';
import axios from 'axios';
import { subscriptions_plans} from '../ServiceConnection/serviceconnection.js';
import ManageSubscriptions from './ManageSubscriptions';
import Header from './Header';
import Footer from './Footer';
function Pricing() {
    const [plans, setPlans] = useState([]);
    const getPlanDetail = () => {
        const headers = {
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        axios.get(subscriptions_plans,headers)
          .then(function (response) {
            const data = response.data;
            setPlans(data.data);
            /* const first_name_vl = data.data.first_name;
            const last_name_vl = data.data.last_name;
            const email_vl = data.data.email;
            setFirstNameFt(first_name_vl);
            setLastNameFt(last_name_vl);
            setEmailft(email_vl); */
          })
          .catch(function (error) {
            console.error(error);
          });
      };
      
      useEffect(() => { 
      getPlanDetail();
    }, []);
    return (
        <div className='pricing'>
          {/* <div>
            <Header />
          </div> */}
            <div className='pricing_wrapper'>
                
                <div>
                    <ManageSubscriptions />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Pricing;