import React, { useState, useRef, useEffect } from 'react';
import '../Css/ForgotPassword.css';
import { Link, useNavigate } from 'react-router-dom';
import OptImg from '../Assests/images/sign_in_page_img.png';
import { Modal, Button } from 'react-bootstrap';
import Success from '../Assests/images/success_gif.gif'
import Failed from '../Assests/images/failed_gif.gif'
import Header from './Header';
import $ from 'jquery';
import axios from 'axios';
import { appauth_key, forgot_passwprd_otp,verify_otp } from '../ServiceConnection/serviceconnection.js';
function VerifyAccount() {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [timer, setTimer] = useState(30);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const navigate = useNavigate();
    const inputRefs = useRef([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const handleChange = (e, index) => {
        const { value } = e.target;
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);

        if (value === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (value !== '' && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleVerify = () => {
        const enteredOtp = otp.join('');
      
        const headers = {
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        setLoading(true);
        const jsonData = {};
        jsonData['email'] = localStorage.getItem('forgot_email');
        jsonData['Otp'] = enteredOtp;
        jsonData['appauth_key'] = appauth_key;
        axios.post(verify_otp,jsonData, headers)
            .then(function (response) {
                setLoading(false);

                if(response.data.status!=='success'){
                  $('.server_error').text(response.data.msg);
                  setShowErrorModal(true);
                }else{ 
                // Session Storage
                localStorage.setItem('forgot_otp_very','1');
                navigate('/reset_password');
                }
            }).catch(function (error) {
                setShowErrorModal(true);
                console.error(error);
                $('.server_error').text(error);
                setLoading(false);
              });
    
    };

    const handleResendOTP = () => {
        
        const jsonData = {};
            jsonData['email'] = localStorage.getItem('forgot_email');
            jsonData['appauth_key'] = appauth_key;
            otp_resend(jsonData);
    };

    const otp_resend = async (jsonData) => {
        
            const headers = {
                "Content-Type": "application/json", // Change the content type to handle file upload
            };
            setLoading(true);
            
            axios.post(forgot_passwprd_otp,jsonData, headers)
                .then(function (response) {
                    setLoading(false);

                    if(response.data.status!=='success'){
                      $('.server_error').text(response.data.msg);
                    }else{ 
                    // Session Storage
                    setTimer(30); // Reset the timer to 30 seconds
        setIsTimerRunning(true); // Start the timer
                    }
                }).catch(function (error) {
                    console.error(error);
                    $('.server_error').text(error.message);
                    // Handle the error here
                    setLoading(false);
                    // You can display an error message or perform any necessary actions
                  });
        
            
        };

    useEffect(() => {
            if(!localStorage.getItem('forgot_email')){
                navigate('/forgot_password');
            }
        let interval;

        if (isTimerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            setIsTimerRunning(false);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer,navigate]);

    const isVerifyButtonDisabled = otp.some((value) => value === ''); // Check if any input box is empty

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <div className='forgot_password'>
            {/* <div>
                <Header />
            </div> */}
            <div className='forgot_password_wrapper'>
                <div className='forgot_password_container row col-xl-6 col-lg-7 col-md-9 col-sm-10 shadow m-auto p-0'>
                    <div className='left_section col-md-4'>
                        <div className='forgot_img_div'>
                            <img src={OptImg} alt='otp' />
                        </div>
                    </div>
                    <div className='right_section col-md-7 m-auto'>
                        <div className='form_div col-md-9 col-10 m-auto'>
                            <h2 className='text-center'>Validate OTP</h2>
                            <p>Enter the OTP you have received on your email to verify your account.</p>
                            <div className='input_fields'>
                                <div className='otp_input verify_input'>
                                    {otp.map((value, index) => (
                                        <input
                                            key={index}
                                            type='text'
                                            maxLength='1'
                                            value={value}
                                            onChange={(e) => handleChange(e, index)}
                                            ref={(input) => (inputRefs.current[index] = input)}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='otp_btn_div text-center'>
                                <div
                                    className={`btn otp_btn ${isVerifyButtonDisabled ? 'disabled' : ''}`}
                                    onClick={handleVerify}
                                    disabled={isVerifyButtonDisabled}
                                >
                                    Verify
                                </div>
                            </div>
                            <div className='back_btn'>
                                <Link to='/forgot_password'>
                                    <span>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#F58634' className='bi bi-arrow-left-circle-fill' viewBox='0 0 16 16'>
                                            <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z' />
                                        </svg>
                                    </span>
                                    <span className='goback_text'>Edit email</span>
                                </Link>
                                <div className='resend_btn'>
                                    {timer === 0 ? (
                                        <div className='resend_otp_btn' onClick={handleResendOTP}>
                                            Resend OTP
                                        </div>
                                    ) : (
                                        <div className='timer'>Resend in&nbsp;<span>{timer}</span>s</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered backdrop="static">
                <Modal.Body>
                    <div className='verify_modal'>
                        <img src={Success} alt='icon' />
                        <p className='text-center'>OTP sent successfully!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='close_btn' onClick={handleCloseSuccessModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered backdrop="static">
                <Modal.Body>
                    <div className='verify_modal'>
                        <img src={Failed} alt='icon' />
                        <p className='text-center'>Wrong OTP, Enter correct OTP!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='close_btn' onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VerifyAccount;
