import React from "react";
import "../Css/ManageSubscriptions.css";

function ManageSubscriptions() {
  return (
    <div className="manage_subscritptions">
      <div className="manage_subscritptions_wrapper">
        <div className="manage_subscritptions_container container-lg">
          <div className="manage_subscriptions_heading">
            <h2>Subscription</h2>
          </div>
          {/*
          <div className="api_div">
            <p className="api_key">API Keys :</p>
            <p className="api_text">
              API Key is available for paid plans only.
            </p>
          </div>
  */}
          <div className="active_plan_details">
            <div className="row">
              <div className="plan_details col-md-6">
                <div>
                  <p>Your current plan: Free</p>
                  <div className="plan_detalis_list">
                    <ul>
                      <li>3 / 6 message credits per day</li>
                      <li>1 / 1 chatbots</li>
                      <li>100,000 characters per chatbot</li>
                      {/*<li>Period: 2023-05-29 05:30 - 2023-05-30 05:29</li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="plan_upgrade col-md-6">
                <div className="text&btn_div">
                  <p>Looking to increase limit?</p>
                  <div className="upgrade_now_btn_div">
                  <a href='/pricing'>
                    <button>Upgrade Now</button>
                  </a> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="billing_div">
            <div>
                <h5>Billing</h5>
                <p>Manage my billing</p>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default ManageSubscriptions;
