import React from "react";
import '../Css/FriendsChatbot.css'
import Header from "./Header";
import Footer from "./Footer";

function FriendsChatbot() {
  return (
    <div className="friends_chatbot">
      {/* <div>
        <Header />
      </div> */}
      <div className="friends_chatbot_wrapper">
        <div className="friends_chatbot_heading">
          <h2>Chatnext</h2>
          <p>Friends of Chatnext</p>
        </div>
        <div className="friends_chatbot_container m-auto col-xl-5 col-lg-6 col-md-7">
          <div className="form_head_text col-sm-10 m-auto">
            <p>
              Join Friends of Chatnext and receive a 20% commission on all
              payments within the first 12 months for paying customers you refer
              to chatnext.co!
            </p>
          </div>
          <form>
            <div className="friends_input_field m-auto col-lg-8 col-md-10 col-sm-9">
              <div>
                <input type="text" placeholder="First Name"></input>
              </div>
              <div>
                <input type="text" placeholder="Last Name"></input>
              </div>
              <div>
                <input type="email" placeholder="Email"></input>
              </div>
              <div>
                <input type="password" placeholder="Password"></input>
              </div>
              <div>
                <input type="password" placeholder="Confirm password"></input>
              </div>
              <div className="captcha_div">
                {/* Human verification captcha */}
              </div>
              <div className="sign_up_btn_friends">
                <button className="btn friends_signup">Sign up</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FriendsChatbot;
