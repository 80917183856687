

const appauth_key = "cjR4Y3hGTHlpLzcwQ2pjUG9CNWFNUT09";
let api_link2='http://localhost/react_api/';
//let api_link='http://143.110.242.217:8025/api/';
///let api_link='http://65.1.77.165:7998/api/';
//let api_link='https://api.chatnext.co/';
let api_link='https://api.chatnext.co/';
//let api_link='http://127.0.0.1:7998/';

const post_api = api_link + 'api/customer/';
const setting_page = api_link + 'api/customer/';
//const chatboat_save = api_link2 + 'chatboat_save.php';
const chatboat_api = (customerId) => api_link+ 'api/chatbot/' + customerId;
const chatboat_api_save =  api_link+ 'api/chatbot/';
const chatboat_text_save =  api_link+ 'api/lanscr/text';
const chatboat_by_id = (chatbot_id) => api_link+ 'api/setting/' + chatbot_id;
const chatboat_setting_update =  api_link+ 'api/setting/';
const sign_in = api_link + 'api/customer/login';
const gsign_in = api_link + 'api/customer/glogin';
const tookan_refresh_api = api_link + 'api/customer/tookan_refresh';
const sign_up = api_link + 'api/customer/';
const email_verify  = (ids) => api_link + 'api/customer/email_verify/'+ ids;
const user_detail_by_id = (customerId) => api_link+ 'api/customer/' + customerId;
const profile_update = api_link + 'api/customer/update';
const forgot_passwprd_otp = api_link + 'api/customer/otp';
const verify_otp = api_link + 'api/customer/verifyotp';
const change_password = api_link + 'api/customer/change_password/';
const subscriptions_plans = api_link + 'api/plan/';
const domain_urls = api_link + 'api/lanscr/url';
const url_scraped = api_link + 'api/lanscr/content';
const chatbychatbotid = (chatbot_id, start_date, end_date) => api_link+ 'api/lanscr/chatbychatbot/'+chatbot_id+'/'+start_date+'/'+end_date;
const chatconversationretival = (session_id, chatbot_id) => api_link+ 'api/lanscr/chatconversationretival/'+session_id+'/'+chatbot_id;
const chat = api_link + 'api/lanscr/chat';
const deletechat = api_link + 'api/lanscr/deletechat';
const updateknowledge = (chatbot_id) => api_link+ 'api/chatbot/updateknowledge/' + chatbot_id;
const chatboat_api_update = api_link+ 'api/chatbot/chatboatupdate/';
const dashboarddatas = (chatbot_id, start_date, end_date) => api_link+ 'api/lanscr/dashboarddata/'+chatbot_id+'/'+start_date+'/'+end_date;
const payment_save = api_link+ 'api/stripe/paymentdatasave';
//admin api
const admin_sign_in = api_link + 'api/adminurl/login';
const user_detail = api_link+ 'api/customer/'; //all user details
const admindashboarddata = (start_date, end_date) => api_link+ 'api/adminurl/admindashboarddata/'+start_date+'/'+end_date;
const getallchatbotlist = (start_date, end_date) => api_link+ 'api/adminurl/getallchatbotlist/'+start_date+'/'+end_date;
const adminchatconversationretival = (session_id) => api_link+ 'api/adminurl/chatconversationretival/'+session_id;

export { api_link,appauth_key,forgot_passwprd_otp,verify_otp,dashboarddatas,change_password,user_detail,chatboat_api,chatboat_api_save,chatboat_text_save,chatboat_by_id,chatboat_setting_update,setting_page,post_api,sign_in,gsign_in,email_verify,tookan_refresh_api,sign_up,user_detail_by_id,profile_update,subscriptions_plans,domain_urls,url_scraped,chatbychatbotid,chatconversationretival,chat,deletechat,updateknowledge,chatboat_api_update,payment_save,admin_sign_in,admindashboarddata,getallchatbotlist,adminchatconversationretival};