import React, { useState, useEffect } from "react";
import "../Css/BasicSetting.css";
// import $ from 'jquery';
import axios from 'axios';
import { appauth_key, chatboat_setting_update } from '../ServiceConnection/serviceconnection.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { showErrorMsg, showSuccessMsg } from './ToastNotifications';

function BasicSetting({ chatbotdata }) {
  // const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [chatbotname, SetChatbotName] = useState('');
  const [accessibility, SetAccessibility] = useState(false);
  const [removebranding, SetRemovebranding] = useState(false);
  const [chatbotrole, SetChatbotRole] = useState('');
  const [collectvisitorinformation, SetCollectVisitorInformation] = useState(false);
  const [contact_data, Setcontact_data] = useState(false);
  const [rmvbrnd, Setrmvbrnd] = useState(false);
  const [sliderValue, setSliderValue] = useState('.7');
  const [AI_model, SetAI_model] = useState('');
  const [disabledaimodal1, Setdisabledaimodal1] = useState(false);
  const [disabledaimodal2, Setdisabledaimodal2] = useState(false);
  // const [when, SetWhen] = useState();
  // const [systemprompt, SetSystemPrompt] = useState();
  const [visitorRecord, setVisitorRecord] = useState({
    Ask_for_Name: false,
    Ask_for_Email: false,
    Ask_for_Phone: false,
  });

  useEffect(() => {
    console.log(chatbotdata.Gpt_type);
    const explodedArray = chatbotdata.Gpt_type;
    if(explodedArray){
    const explodedArray1 = explodedArray.split(',');
    if(!explodedArray1[0]){
      Setdisabledaimodal1(true)
    }
    if(!explodedArray1[1]){
      Setdisabledaimodal2(true)
    }
    }
    
    
    SetChatbotName(chatbotdata.chatbot_name || '');
    SetChatbotRole(chatbotdata.chatbot_role || 'Customer Service');
    SetCollectVisitorInformation(chatbotdata.collect_visitor_information || false);
    SetAccessibility(chatbotdata.accessibility || false);
    SetRemovebranding(chatbotdata.Remove_Branding || false);
    setSliderValue(chatbotdata.temperature || 0.7);
    SetAI_model(chatbotdata.AI_model || 'gpt-3.5-turbo-0613');
    setVisitorRecord((prevRecord) => ({
      ...prevRecord,
      Ask_for_Name: chatbotdata.Ask_for_Name,
      Ask_for_Email: chatbotdata.Ask_for_Email,
      Ask_for_Phone: chatbotdata.Ask_for_Phone,
    }));
    console.log(chatbotdata.contact_data)
    if(chatbotdata.contact_data=='NO'){
      SetCollectVisitorInformation(false)
      setVisitorRecord({
        Ask_for_Name: false,
        Ask_for_Email: false,
        Ask_for_Phone: false,
      });
      Setcontact_data(false);
    }else{
      Setcontact_data(true);
    }

    if(chatbotdata.branding=='NO'){
      SetRemovebranding(false)
      Setrmvbrnd(false);
    }else{
      Setrmvbrnd(true);
    }
    // SetWhen(chatbotdata.when);
    // SetSystemPrompt(chatbotdata.system_prompt);

    

  }, [chatbotdata]);


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (collectvisitorinformation) {
      setVisitorRecord((prevRecord) => ({
        ...prevRecord,
        [name]: checked,
      }));
    }
  };

  const handleCheckboxToogle = (e) => {
    const { checked } = e.target;
    SetCollectVisitorInformation(checked);
    if (!checked) {
      SetCollectVisitorInformation(false)
      setVisitorRecord({
        Ask_for_Name: false,
        Ask_for_Email: false,
        Ask_for_Phone: false,
      });
    }
  };

  useEffect(() => {

  }, [handleCheckboxToogle]);



  const validateForm = () => {
    var isValid = true;
    if (isValid === true) {
      if (isValid === true) {
        const formData = new FormData(document.getElementById('save_form')); // Get form data
        formData.append('appauth_key', appauth_key);
        const jsonData = {};
        for (let [key, value] of formData.entries()) {
          //if(filesing)

          jsonData[key] = value;
        }
        jsonData['Collect_visitor_information'] = collectvisitorinformation;
        jsonData['Ask_for_Email'] = visitorRecord.Ask_for_Email;
        jsonData['Ask_for_Name'] = visitorRecord.Ask_for_Name;
        jsonData['Ask_for_Phone'] = visitorRecord.Ask_for_Phone;
        jsonData['Chatbot_id'] = chatbotdata.chatbot_id;
        jsonData['Accessibility'] = accessibility;
        jsonData['Remove_Branding'] = removebranding;
        saving_data(jsonData); // Pass the form data to the saving_data function
        console.log(jsonData);
      }
    }
  };







  const saving_data = async (jsonData) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    setLoading(true);
    axios.patch(chatboat_setting_update, jsonData, headers)
      .then(function (response) {
        setLoading(false);
        if (response.data.status != 'success') {
          showErrorMsg(response.data.message);
        } else {
          showSuccessMsg();
        }
      }).catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };

  // slider 
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const handleSliderChange = (event, newValue) => {
    // Update the state with the new value when slider changes
    setSliderValue(newValue);
  };

  console.log(sliderValue)



  return (
    <div className="basic_setting">
      <div className="basic_setting_container">
        <form className="form_div col-md-10 col-12" id='save_form'>
          <div className="input_div row">
            <span className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
              <div className="nowrap_text">Chatbot ID</div>
              <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </span>
            <span className="chatbot_id_number_span col-lg-8 col-md-9 col-sm-8">
              <p className="chatbot_id_number">{chatbotdata.chatbot_id}</p>
            </span>
          </div>

          <div className="input_container">
            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div>Name</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>
              <input
                className="input_box_basic col-lg-5 col-md-6 col-sm-7"
                type="text"
                placeholder="Xyz Organisation" name='Name' onChange={(e) => SetChatbotName(e.target.value)} value={chatbotname}
              ></input>
            </div>

            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div>Accessibility</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>

              <div className="col-sm-8">
                <input
                  type="radio"
                  id="public"
                  checked={accessibility === true}
                  onChange={() => SetAccessibility(true)}
                />
                <label className="radio_lable" htmlFor="public">
                  Public
                </label>

                <input
                  type="radio"
                  id="private"
                  checked={accessibility === false}
                  onChange={() => SetAccessibility(false)}
                />
                <label className="radio_lable" htmlFor="private">
                  Private
                </label>
              </div>
            </div>

            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div className="nowrap_text">AI model</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>
              <select className="col-lg-5 col-md-6 col-sm-7" name='AI_model' value={AI_model} onChange={(e) => SetAI_model(e.target.value)}>
              
                <option value="gpt-3.5-turbo-0613" disabled={disabledaimodal1} >gpt-3.5-turbo</option>
                <option value="gpt-4" disabled={disabledaimodal2} >gpt-4</option>
                
              </select>
            </div>
            
            {/*
            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div className="nowrap_text">Chat GPT role</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>
              <select className="col-lg-5 col-md-6 col-sm-7" name='Chatbot_role' value={chatbotrole} onChange={(e) => SetChatbotRole(e.target.value)}>
                <option value="Customer Service">Customer Service</option>
                <option value="Answer Questions About Data">Answer Questions About Data</option>
              </select>
            </div>
  */}

            <div className="input_div temp_slider">
              <div className="row">
                <label className="label_style slider_label_style nowrap_text1 col-xl-3">Chatbot temperature :&nbsp;&nbsp;</label>
                <div className="col-xl-7">
                  <Box sx={{ width: 300 }}>
                    <Slider
                      aria-label="Custom marks"
                      // defaultValue={1.5}
                      getAriaValueText={valuetext}
                      value={sliderValue}
                      onChange={handleSliderChange}
                      step={0.1}
                      valueLabelDisplay="auto"
                      marks={marks}
                      min={0}
                      max={1}
                      name="temperature"
                    />
                  </Box>
                </div>
              </div>
              <div>
                <p>Lower temperature = deterministic and repetitive chatbot, higher temperature = creative chatbot.</p>
              </div>
            </div>

            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div>Remove Branding</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>

              <div className="col-sm-8">
                <input
                  type="radio"
                  id="Yes_Remove" className={rmvbrnd ? '' : 'radio_lable_disabled'}
                  checked={removebranding === true}
                  onChange={() => SetRemovebranding(true)}
                />
                <label className={rmvbrnd ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="public">
                  Yes
                </label>

                <input
                  type="radio"
                  id="No_Remove" className={rmvbrnd ? '' : 'radio_lable_disabled'}
                  checked={removebranding === false}
                  onChange={() => SetRemovebranding(false)}
                />
                <label className={rmvbrnd ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="private">
                  No
                </label>
              </div>
            </div>

            <div className="input_div">
              <label className="label_style nowrap_text1">Collect visitor information</label>
              <label className={contact_data ? 'switch' : 'switch radio_lable_disabled'}>
                <input
                  type="checkbox"
                  name="Collect_visitor_information"
                  checked={collectvisitorinformation}
                  onChange={handleCheckboxToogle}
                  
                />
                <span className="slider round"></span>
              </label>
              <div className="text_below_toggle">
                <p>
                  When enabled, your chatbot will ask visitors for their
                  information based on specific actions.
                </p>
              </div>
            </div>

            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div>Ask for</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>
              <div className="radio_btns_input col-sm-8">
                <input type="checkbox" name='Ask_for_Name'
                  onChange={handleCheckboxChange}
                  checked={visitorRecord.Ask_for_Name} />
                <label className={collectvisitorinformation ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="vehicle1">
                  Name
                </label>
                <input type="checkbox" name='Ask_for_Email'
                  onChange={handleCheckboxChange}
                  checked={visitorRecord.Ask_for_Email} />
                <label className={collectvisitorinformation ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="vehicle2">
                  Email
                </label>
                <input type="checkbox" name='Ask_for_Phone'
                  onChange={handleCheckboxChange}
                  checked={visitorRecord.Ask_for_Phone} />
                <label className={collectvisitorinformation ? 'radio_lable' : 'radio_lable radio_lable_disabled'} htmlFor="vehicle3">
                  Phone
                </label>
              </div>
            </div>
            {/*
            <div className="input_div row">
              <label className="label_style d-flex justify-content-between align-items-center col-md-3 col-sm-4 col-5">
                <div>When</div>
                <div>:&nbsp;&nbsp;&nbsp;&nbsp;</div>
              </label>
              <input
                className="input_box_basic col-md-8 col-sm-7"
                type="text" name='when' onChange={(e) => SetWhen(e.target.value)} value={when}
                placeholder="Eg: Customer wants to book an appointment, customer want someone to call back"
              ></input>
            </div>
  */}
            {/*
            <div className="input_div row">
              <label className="label_style">System Prompt</label>
              <br></br>
              <div className="textarea_basic_setting col-12">
                <textarea
                style={{width:'100%'}}
                name='systemprompt' onChange={(e) => SetSystemPrompt(e.target.value)} value={systemprompt}
                  rows="4"
                  cols="50"
                ></textarea>
              </div>
            </div>
            */}
          </div>

        </form>
        <div
          className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
        >
          <div className='loader'></div>
        </div>
        <div className="update_btn_div">
          <div className='btn update_btn' onClick={validateForm}>
            Update
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BasicSetting;
