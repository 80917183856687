import React from 'react';
import SideBar from './SideBar';

function Dashboard() {
    return (
        <div className='dashboard'>
            <div className='dashboard_wrapper'>
                <div className='dashboard_contaier'>
                    <div className='col-xl-2 col-lg-3'>
                        <SideBar />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;