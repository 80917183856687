import React, { useEffect, useRef, useState, useCallback } from "react";
import "../Css/Conversation.css";
import $ from "jquery";
import moment from "moment";
import "moment/locale/en-au";
import "daterangepicker";
import SideBar from "./SideBar";
import { Link, useLocation } from "react-router-dom";
import PointerSvg from "../Assests/images/pointer_svg.svg";
import HelloIcon from '../Assests/images/hellow_icon.png'
import Header from "./Header";
import axios from 'axios';
import { appauth_key, chatbychatbotid, chatconversationretival, chatboat_by_id } from '../ServiceConnection/serviceconnection.js';
let start_for_chatbotiddata = "";
const Conversation = () => {
  const location = useLocation();
  const inputRef = useRef(null);
  const [chatdataarray, SetChatdataArray] = useState([]);
  const [chatconverarray, SetChatConverArray] = useState([]);
  const [chatbotiddata, SetChatbotidData] = useState([]);

  const [firstmsg, SetFirstMsg] = useState('Hello! What can I do for you today?');
  const [usermsgtext, SetUserMsgText] = useState('#ffffff');
  const [usermsgbackground, SetUserMsgBackground] = useState('#800080');
  const [botmsgtext, SetBotMsgText] = useState('#ffffff');
  const [botmsgbackground, SetBotMsgBackground] = useState('#800080');

  const handleDateRangeChange = (start, end, label) => {
    // Callback function for handling date range changes
    let startDate = start.format("DD-MM-YYYY").toString();
    let endDate = end.format("DD-MM-YYYY").toString();
    //chatbotiddata
    if (!start_for_chatbotiddata) {
      // chatbotiddata is not set, handle the case appropriately
      return;
    }
    handlecallevnt(startDate, endDate);


  };

  const handlecallevnt = useCallback((startDate, endDate) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    //chatbotiddata
    axios.get(chatbychatbotid(start_for_chatbotiddata, startDate, endDate), headers)
      .then(function (response) {

        const data = response.data;
        SetChatdataArray(data.data);
        console.log(data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [chatbotiddata]);

  useEffect(() => {
    const currentUrl = location.pathname;
    const pathnameParts = currentUrl.split('/');
    const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
    console.log('Second Value:', secondValue);
    start_for_chatbotiddata = secondValue;
    SetChatbotidData(secondValue);
    const datepicker = $(inputRef.current);
    datepicker.daterangepicker(
      {
        timePicker: true,
        startDate: moment().startOf("hour"),
        endDate: moment().startOf("hour").add(32, "hour"),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      handleDateRangeChange
    );

    // Call handleDateRangeChange on component load
    const startDate = datepicker.data("daterangepicker").startDate;
    const endDate = datepicker.data("daterangepicker").endDate;
    handleDateRangeChange(startDate, endDate);


    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    axios.get(chatboat_by_id(start_for_chatbotiddata), headers)
      .then(function (response) {
        const chatbotdata = response.data.data;

        SetUserMsgText(chatbotdata.User_Message_text);
        SetUserMsgBackground(chatbotdata.User_Message_background);
        SetBotMsgText(chatbotdata.Bot_Message_text);
        SetBotMsgBackground(chatbotdata.Bot_Message_background);


      })
      .catch(function (error) {
        console.error(error);

      });
  }, []);



  const chatconversation = (chat_session) => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    // chatbotiddata
    axios.get(chatconversationretival(chat_session, start_for_chatbotiddata), headers)
      .then(function (response) {

        const data = response.data;
        console.log(data);
        if (response.data.status !== 'success') {
          //$('.server_error').text(response.data.msg);
        } else {
          SetChatConverArray(data.data);
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.data.message) {
          console.error(error.response.data.message);
        } else {
          console.error('something went wrong');
        }
      });
  };


  const getFormattedTimestamp = (timestamp) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const messageTimestamp = moment(timestamp);

    if (messageTimestamp.isSame(today, 'd')) {
      return messageTimestamp.format('h:mm A'); // Today with time (e.g., 3:30 PM)
    } else if (messageTimestamp.isSame(yesterday, 'd')) {
      return 'Yesterday ' + messageTimestamp.format('h:mm A'); // Yesterday with time (e.g., Yesterday 3:30 PM)
    } else {
      return messageTimestamp.format('DD-MM-YYYY h:mm A'); // Date with time (e.g., 10-07-2023 3:30 PM)
    }
  };


  return (
    <div className="conversation custom_qa setting">
      {/* <div>
        <Header />
      </div> */}
      <div className="conversation_wrapper custom_qa_wrapper setting_wrapper">
        <div className="conversation_container custom_qa_container setting_container">
          <div>
            <SideBar />
          </div>
          <div className="w-100 mr-auto ml-0" style={{ marginTop: "1rem" }}>
            <div className="col-lg-12">
              <div className="custom_qa_head_container">
                <div className="conversation_head custom_qa_head">
                  <div
                    className="btn sidebar_toggle_btn d-block d-lg-none"
                    id="sidebar_toggle_btn"
                  >
                    <div className="rotate_arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </div>
                  </div>
                  <h3>Conversation</h3>
                </div>
                <div className="date_filter_div">
                  <div className="date_filter">
                    <input
                      className="form-control form-control-solid"
                      placeholder="Pick date range"
                      ref={inputRef}
                      id="aaaaaaaa"
                    />
                    <label htmlFor="aaaaaaaa" className="calender_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-calendar-week-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zM2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
              {chatdataarray.length === 0 ? (
                <div className="no_conversation_div">
                  <h4>No conversations yet</h4>
                </div>
              ) : (
                <div className="chat_history_container">
                  <div className="row">
                    <div className="chat_history_list_container col-xl-5 col-md-6">
                      <div style={{ borderRadius: '20px', overflow: 'hidden', marginBottom: '1rem' }}>
                        <div className="chat_history_list">
                          {chatdataarray.map((chatboatdata) => {
                            const timestamp = getFormattedTimestamp(chatboatdata.first_created_at); // Get formatted timestamp

                            return (
                              <div className="chat_list" key={chatboatdata.session_id} onClick={() => chatconversation(chatboatdata.session_id)}>
                                <div className="chat_list_head">
                                  <h6>
                                    (Unknown) <span></span>
                                  </h6>
                                  <p className="time_text">{timestamp}</p> {/* Render the formatted timestamp */}
                                </div>
                                <div className="chat_list_body">
                                  <p>{chatboatdata.first_content}</p> {/* Render the first content of the session */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                    </div>
                    <div className="selected_chat_container col-xl-7 col-md-6">
                      {chatconverarray.length === 0 ? (
                        <div className="selected_chat_history">
                          <div className="no_chat_selected">
                            <div className="text-center">
                              <img src={PointerSvg} alt="icon" />
                              <h6>No chat selected.</h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ borderRadius: '20px', overflow: 'hidden', marginBottom: '1rem' }}>
                          <div className="selected_chat_history">
                            <div className="chat_selected">
                              <div className="msg_div">
                                {chatconverarray.map((chatboatdata, i) => (
                                  <React.Fragment key={i}>
                                    {i === 0 && (
                                      <div className="converssation_sent_msg">
                                        <p className="m-0 mb-1 pl-2" style={{ fontWeight: '400' }}>Bot</p>
                                        <div className="sent_msg" style={{ 'color': botmsgtext, 'backgroundColor': botmsgbackground }}>
                                          <img src={HelloIcon} alt='icon' />
                                          <p>Hello! What can I do for you today?</p>
                                        </div>
                                      </div>
                                    )}
                                    <div className="converssation_recived_msg">
                                      <p className="m-0 mb-1 ml-2 pr-2 text-right" style={{ fontWeight: '400' }}>User</p>
                                      <div className="received_msg" style={{ 'color': usermsgtext, 'backgroundColor': usermsgbackground }}>
                                        <p>{chatboatdata.content}</p>
                                      </div>
                                    </div>
                                    <div className="converssation_sent_msg">
                                      <p className="m-0 mb-1 pl-2" style={{ fontWeight: '400' }}>Bot</p>
                                      <div className="sent_msg" style={{ 'color': botmsgtext, 'backgroundColor': botmsgbackground }}>
                                        <p>{chatboatdata.response}</p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ))}
                              </div>


                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Conversation;
