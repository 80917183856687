import React, { useState } from "react";
import "../Css/CreateChatbotFooter.css";
import CustomerService from "../Assests/images/customer_service_icon.svg";
import DataSearchIcon from "../Assests/images/data_answer_icon.svg";

function CreateChatbotFooter() {
  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  return (
    <div className="create_chatbot_footer">
      <div className="create_chatbot_footer_container">
        <div className="left_div">
          <div className="text_counter_div">
            <p className="plain_text">Plain Text: 0 characters</p>
            <p className="plain_text">File Text: 0 characters</p>
            <p className="plain_text">Website Text: 0 characters</p>
          </div>
        </div>
        <div className="right_div">
          <div className="chatbot_role">
            <p>Chatbot Role:*</p>
            <div className="role_btns">
              <div
                className={`btn selected customer_service_btn ${
                  activeButton === "customerService" ? "selected_btn" : ""
                }`}
                id="selected"
                onClick={() => handleButtonClick("customerService")}
              >
                <img src={CustomerService} alt="icon" />
                <div>Customer Service</div>
              </div>
              <div
                className={`btn selected answer_data_btn ${
                  activeButton === "answerData" ? "selected_btn" : ""
                }`}
                id="selected"
                onClick={() => handleButtonClick("answerData")}
              >
                <img src={DataSearchIcon} alt="icon" />
                <div>Answer Questions About Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="delet_build_btns">
        <div className="total_char_div">
          <p className="total_text m-0">Total characters: 0</p>
        </div>
        <div className="btn build_btn">
          <p>Build Chatbot</p>
        </div>
      </div>
    </div>
  );
}

export default CreateChatbotFooter;
