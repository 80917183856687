import React, { useState, useEffect } from 'react'
import '../Css/EmailVerify.css'
import { useLocation } from "react-router-dom";
import { email_verify } from '../ServiceConnection/serviceconnection.js';
import axios from 'axios';
const ids_enc = '';
function EmailVerify() {
    const location = useLocation();
    const [msgs, Setmsgs] = useState(0);
    const [heda, SetHeda] = useState('');
    useEffect(() => {
        console.log('useEffect is running');
        const currentUrl = location.pathname;
        const pathnameParts = currentUrl.split('/');
        const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
        const ids_enc = secondValue;

        console.log(ids_enc);

        axios.get(email_verify(ids_enc))
            .then(function (response) {
                console.log(response);
                const data = response.data;
                console.log(data.data);
                if(data.status=='error'){
                SetHeda('Try verifying your email');
                }else{    
                    SetHeda('Email Verification');
                }
                Setmsgs(data.data);
            })
            .catch(function (error) {
                const isExpired = error?.response?.data?.data?.expired ?? null;
                if (isExpired == '1') {
                    console.log('logout');
                    //tookan_refresh();
                } else {
                    console.error();
                }
            });
    }, []);

    return (
        <div className='email_verify_box'>
            <div className='email_verify_box_wrapper'>
                <div className='email_verify_box_container'>
                    <h4>{heda}</h4>
                    <p>{msgs}</p>
                </div>
            </div>
        </div>
    )
}

export default EmailVerify