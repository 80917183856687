import React, { useState } from 'react';
import '../Css/CreateByText.css';
import SimpleSearch from '../Assests/images/simple_search.svg';
import DeletSweep from '../Assests/images/delete-sweep.svg';

function Website() {

    const [urls, setUrls] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const addUrl = () => {
        if (inputValue.trim() === '') {
            alert('Please enter a URL.');
            return;
        }

        setUrls([...urls, inputValue]);
        setInputValue('');
    };

    const deleteUrl = (index) => {
        const updatedUrls = urls.filter((_, i) => i !== index);
        setUrls(updatedUrls);
    };

    return (
        <div className="text_input">
            <div className="website_container">
                <h5 className="url_label">
                    Website Url:<span>*</span>
                </h5>
                <div className="url_input">
                    <input
                        type="url"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="website must start with https://"
                    />
                    <div
                        className={`btn crawl_btn ${inputValue.trim() === '' ? 'disabled' : ''}`}
                        onClick={addUrl}
                        disabled={inputValue.trim() === ''}
                    >
                        <span>
                            <img src={SimpleSearch} alt="icon" />
                        </span>
                        <span className="crawl_text">Crawl</span>
                    </div>
                </div>
                {urls.length > 0 && (
                    <div className="url_table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" />
                                    </th>
                                    <th className="page_url">PAGE URL</th>
                                    <th>STATUS</th>
                                    <th>CHARACTER</th>
                                    <th>DELETE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {urls.map((url, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        <td>{url}</td>
                                        <td>
                                            <div className="processed">
                                                <p>Processed</p>
                                            </div>
                                        </td>
                                        <td>3453</td>
                                        <td>
                                            <div className="delet_icon" onClick={() => deleteUrl(index)}>
                                                <img src={DeletSweep} alt="icon" />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Website;