import React, { useState, useEffect, useRef } from "react";
import '../Css/Chat.css'
import SideBar from './SideBar';
// import { Link } from 'react-router-dom';
import HelloIcon from '../Assests/images/hellow_icon.png'
import img_link from '../Assests/images/typing-dots-loading.gif'
import Header from './Header';
import $ from 'jquery'
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import { appauth_key, chat, chatboat_by_id } from '../ServiceConnection/serviceconnection.js';
import { v4 as uuidv4 } from 'uuid';



let start_for_chatbotiddata = "";
function Chat() {
    const location = useLocation();
    const [isDisabled, setIsDisabled] = useState(false);
    const [msgcnt, Setincid] = useState('0');
    const [chatbotiddata, SetChatbotidData] = useState([]);
    const [uuid_vl, Setuuidvl] = useState(uuidv4());
    const [placeholder, SetPlaceholder] = useState('Type Something...');
    const [firstmsg, SetFirstMsg] = useState('Hello! What can I do for you today?');
    const [usermsgtext, SetUserMsgText] = useState('#ffffff');
    const [usermsgbackground, SetUserMsgBackground] = useState('#800080');
    const [botmsgtext, SetBotMsgText] = useState('#ffffff');
    const [botmsgbackground, SetBotMsgBackground] = useState('#800080');

    useEffect(() => {
        const currentUrl = location.pathname;
        const pathnameParts = currentUrl.split('/');
        const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
        SetChatbotidData(secondValue);
        start_for_chatbotiddata = secondValue;


        const headers = {
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        axios.get(chatboat_by_id(start_for_chatbotiddata), headers)
            .then(function (response) {
                const chatbotdata = response.data.data;

                SetPlaceholder(chatbotdata.Input_Placeholder);
                SetFirstMsg(chatbotdata.First_Message);
                SetUserMsgText(chatbotdata.User_Message_text);
                SetUserMsgBackground(chatbotdata.User_Message_background);
                SetBotMsgText(chatbotdata.Bot_Message_text);
                SetBotMsgBackground(chatbotdata.Bot_Message_background);


            })
            .catch(function (error) {
                console.error(error);

            });


    }, []);


    const chatsendfnct = () => {
        const message_textarea = $('#message_textarea').val();
        if (message_textarea.length >= '2' && isDisabled == false) {
            Setincid(Number(msgcnt) + Number(1));
            setIsDisabled(true);
            let snddatc = "<div class='sent_msg received_msg_chatbot_55' style='color:" + usermsgtext + "; background-color: " + usermsgbackground + "'><p>" + message_textarea + "</p></div>";

            var msgDiv = document.querySelector('.msg_div_chatbot_55');
            msgDiv.insertAdjacentHTML('beforeend', snddatc);
            msgDiv.scrollTo({
                top: msgDiv.scrollHeight,
                behavior: 'smooth'
            });
            document.querySelector('#message_textarea').value = '';
            let revdatc = "<div class='received_msg received_msg_chatbot_55' style='color: " + botmsgtext + ";background-color: " + botmsgbackground + "'><p id='chatLog" + msgcnt + "'><img src='" + img_link + "'></p></div>";
            msgDiv.insertAdjacentHTML('beforeend', revdatc);
            msgDiv.scrollTo({
                top: msgDiv.scrollHeight,
                behavior: 'smooth'
            });

            
            axios.defaults.withCredentials = true
            const csrftoken = getCookie('csrftoken');
            const headers = {
                "Content-Type": "application/json", // Change the content type to handle file upload
                'X-CSRFToken': csrftoken,
            };
            console.log(csrftoken);
            const jsonData = {};
            jsonData["content"] = message_textarea;
            jsonData["chatbot_id"] = start_for_chatbotiddata;
            jsonData["session_id"] = uuid_vl;
            axios.post(chat, jsonData, headers)
                .then(function (response) {


                    var responseText = response.data; // Replace with your actual response text
                    var receivedMsgDiv = document.getElementById('chatLog' + msgcnt);
                    receivedMsgDiv.innerHTML = '';
                    if(responseText.response!=''){
                    typeMessage(responseText.response, receivedMsgDiv);
                    }else{
                    setIsDisabled(false);  
                    }

                }).catch(function (error) {
                    
                    if (error.response && error.response.data && error.response.data.exp === '1') {
                        var receivedMsgDiv = document.getElementById('chatLog' + msgcnt);
                        receivedMsgDiv.innerHTML = error.response.data.message;
                    }else{
                        setIsDisabled(false);
                        console.log(error);
                        var receivedMsgDiv = document.getElementById('chatLog' + msgcnt);
                        receivedMsgDiv.innerHTML = error.message;
                    }
                });
        }
    };


    function getCookie(name) {
        const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
        return cookieValue ? cookieValue.pop() : '';
      }




    const typeMessage = (message, element) => {
        var index = 0;

        // Display one letter at a time
        var interval = setInterval(function () {
            element.innerHTML += message[index++];
            var msgDiv = document.querySelector('.msg_div_chatbot_55');
            msgDiv.scrollTo({
                top: msgDiv.scrollHeight,
                behavior: 'smooth'
            });
            // Check if all letters have been displayed
            if (index === message.length) {
                clearInterval(interval);
                setIsDisabled(false);
                console.log('last');
            }
        }, 25); // Delay between displaying each letter (in milliseconds)
    }

    //const $textareaRef = useRef(null);
    const $textareaRef = $('#message_textarea');
    const onKeyPressHandler = (event) => {
        if (event.key === 'Enter') {
            // Check for Enter + Shift (keyCode 13 + shiftKey)
            if (event.shiftKey) {
                event.preventDefault();
                // Add a new line to the textarea
                $textareaRef.val($textareaRef.val() + '\n');
                $textareaRef.addClass('expanded');
                $textareaRef.css('height', 'auto');
                $textareaRef.css('height', $textareaRef.prop('scrollHeight') + 'px');
            } else {
                event.preventDefault();
                chatsendfnct();
                $textareaRef.css('height', '50px');
            }
        } else if (event.key === 'Backspace' && $textareaRef.val().trim() === '') {
            $textareaRef.css('height', '50px');
        }
    };


    return (
        <div className='chatbox'>
            {/* <div>
                <Header />
            </div> */}
            <div className='chatbox_wrapper'>
                <div className='chatbox_container'>
                    <div className='m-auto'>
                        <SideBar />
                    </div>
                    <div className='w-100 mr-auto ml-0'>
                        <div className='chatbox_head'>
                            <div className='chatbox_head_left'>
                                <div className="btn sidebar_toggle_btn d-block d-lg-none" id='sidebar_toggle_btn'>
                                    <div className='rotate_arrow'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                                            <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>
                                <h2>Chat with Bot</h2>
                            </div>
                        </div>
                        <div className='chat_box'>
                            <div className='msg_div msg_div_chatbot_55'>
                                <div className='received_msg received_msg_chatbot_55' style={{ 'color': botmsgtext, 'backgroundColor': botmsgbackground }}>
                                    <img src={HelloIcon} alt='icon' />
                                    <p>{firstmsg}</p>
                                </div>
                            </div>
                            <div className="send_msg_input" id="send_msg_input">
                                <textarea
                                    id="message_textarea"
                                    onKeyDown={onKeyPressHandler}
                                    placeholder={placeholder}
                                    style={{ height: '50px' }}
                                />
                                <div className={`btn send_btn chat_send_btn_chatbot_55 ${isDisabled ? 'sndbtndisabled' : ''}`} onClick={chatsendfnct}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" className="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat;
