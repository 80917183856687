import React, { useState,useEffect } from 'react'
import '../Admin-Css/AdminDashboard.css'
import AdminHeader from './AdminHeader'
import AdminSidebar from './AdminSidebar'
import VisitorSvg from '../../Assests/images/visitor.svg'
import ChatOrange from '../../Assests/images/chat_orange.svg'
import EarningSvg from '../../Assests/images/earning.svg'
import MultiChatSvg from '../../Assests/images/multichat.svg'
import PointerSvg from '../../Assests/images/pointer_svg.svg'
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import {  admindashboarddata  } from '../../ServiceConnection/serviceconnection.js';
function AdminDashboard() {
    const [activeTab, setActiveTab] = useState("overall_tab");
    const [conversationsData, SetconversationsData] = useState([]);
    const [messagesData, SetmessagesData] = useState([]);
    const [chatbotvls, Setchatbotvls] = useState(0);
    const [subscribervls, Setsubscribervls] = useState(0);
    const [earningvls, Setearningvls] = useState(0);
    const [visitorvls, Setvisitorvls] = useState(0);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
      };
    useEffect(() => {
        const headers = {
            "Content-Type": "application/json", // Change the content type to handle file upload
          };
          //chatbotiddata
          let startDate = '1';
          let endDate = '1'; 
          const currentDate = new Date();
          if(activeTab==='this_year_tab'){
             startDate = formatDate(new Date(currentDate.getFullYear(), 0, 1));
             endDate = formatDate(new Date(currentDate.getFullYear(), 11, 31));
          }else if(activeTab==='this_month_tab'){
             startDate = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
             endDate = formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
          }else{
             startDate = '1';
             endDate = '1'; 
          }

          axios.get(admindashboarddata(startDate, endDate), headers)
            .then(function (response) {
      console.log(response.data.data);
              const data = response.data;
              Setchatbotvls(data.data['total_chatbot']);
              Setsubscribervls(data.data['total_subscription']);
              Setearningvls(data.data['total_earning']);
              Setvisitorvls(data.data['total_visitor']);
              const transformedData = data.merged_monthwise_data.map(item => ({
                month: item.month, // Assuming date_created is in the format "yyyy-MM-dd"
                count: item.total_subscriptions,
                msgcount: item.total_visitors,
              }));
              console.log(transformedData);
              SetconversationsData(transformedData)
              SetmessagesData(transformedData)
              
            })
            .catch(function (error) {
              console.error(error);
            });
    
        }, [activeTab]);

    Chart.register(...registerables);


    const data = {
        labels: conversationsData.map(row => row.month),
        datasets: [
            {
                label: 'Subscribers',
                data: conversationsData.map(row => row.count),
                backgroundColor: '#8fdd57',
                barThickness: 20,
                borderWidth: 0,
                borderRadius: 5
            },
            {
                label: 'Visitors',
                data: messagesData.map(row => row.msgcount),
                backgroundColor: '#fb863d',
                barThickness: 20,
                borderWidth: 0,
                borderRadius: 5
            },
        ],
    };

    var options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                }
            },
            x: {
                grid: {
                    display: false
                },
            }
        }
    };
    return (
        <div className='admin_dashboard'>
            {/* <div>
                <AdminHeader />
            </div> */}
            <div className='admin_dashboard_wrapper'>
                <div className='admin_dashboard_container'>
                    <div>
                        <AdminSidebar />
                    </div>
                    <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem" }}>
                        <div className='admin_dashboard_head w-100'>
                            <div
                                className="btn sidebar_toggle_btn d-block d-lg-none"
                                id="sidebar_toggle_btn"
                            >
                                <div className="rotate_arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="#800080"
                                        className="bi bi-chevron-double-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="admin_duration_tabs">
                                <div onClick={() => handleTabClick("overall_tab")}>
                                    <p className={activeTab === "overall_tab" ? "active_tab1" : ""}>
                                        Overall
                                    </p>
                                </div>
                                <div onClick={() => handleTabClick("this_month_tab")}>
                                    <p className={activeTab === "this_month_tab" ? "active_tab1" : ""}>
                                        This Month
                                    </p>
                                </div>
                                <div onClick={() => handleTabClick("this_year_tab")}>
                                    <p className={activeTab === "this_year_tab" ? "active_tab1" : ""}>
                                        This Year
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard_cards_container'>
                            <div className='dashboard_cards_row row'>
                                <div className="card col-md-2 mx-auto">
                                    <img className="card-img-top" src={ChatOrange} alt="Card cap" />
                                    <div className="card-body">
                                        <p className="card-text">chatbotvls</p>
                                        <h4>{chatbotvls}</h4>
                                    </div>
                                </div>
                                <div className="card col-md-2 mx-auto">
                                    <img className="card-img-top" src={PointerSvg} alt="Card cap" />
                                    <div className="card-body">
                                        <p className="card-text">Subscriber</p>
                                        <h4>{subscribervls}</h4>
                                    </div>
                                </div>
                                <div className="card col-md-2 mx-auto">
                                    <img className="card-img-top" src={EarningSvg} alt="Card cap" />
                                    <div className="card-body">
                                        <p className="card-text">Earning</p>
                                        <h4>₹{earningvls}</h4>
                                    </div>
                                </div>
                               
                                <div className="card col-md-2 mx-auto">
                                    <img className="card-img-top" src={VisitorSvg} alt="Card cap" />
                                    <div className="card-body">
                                        <p className="card-text">Visitor</p>
                                        <h4>{visitorvls}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='admin_dashboard_barchart'>
                            <div className='admin_dashboard_barchart_container'>
                                <div className='graphs_admin_dashboard'>
                                    <Bar data={data} options={options} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard