import React, { useState } from "react";
import "../Css/CustomQA.css";
import SideBar from "./SideBar";
import CustomQASvg from "../Assests/images/custom_qa_svg.svg";
import BackSvg from "../Assests/images/round_back.svg";
import Delete2Svg from '../Assests/images/delete2_svg.svg'
import $ from 'jquery';
import axios from 'axios';
import { appauth_key,post_api } from '../ServiceConnection/serviceconnection.js';
import Header from "./Header";

function CustomQA() {
  const [isContainerVisible, setContainerVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const chatbot_id = localStorage.getItem('chatbot_id');
  const handleBtnClick = () => {
    setContainerVisible(!isContainerVisible);
  };

  const handleAddQuestion = () => {
    const questionInput = document.getElementById("questionInput");
    const answerInput = document.getElementById("answerInput");

    if (questionInput.value.trim() !== "" && answerInput.value.trim() !== "") {
      const newQuestion = {
        question: questionInput.value,
        answer: answerInput.value
      };

      setQuestions([...questions, newQuestion]);
      questionInput.value = "";
      answerInput.value = "";
    }
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const validateForm = () => {
    var isValid = true;
    
    


        if (isValid === true) {
              const jsonData = {};

              
              jsonData["chatbot_id"] = chatbot_id;
              questions.forEach(function (value,key) {
                jsonData["qstn["+key+"]"] = value.question;
                jsonData["ans["+key+"]"] = value.answer;
               // jsonData[key] = value;
              });
              jsonData["appauth_key"] = appauth_key;
            if(chatbot_id===localStorage.getItem('chatbot_id')){
              saving_data(jsonData); // Pass the form data to the saving_data function
            }else{
              //console.log('id not match');
            }

            
        }

};


const saving_data = async (jsonData) => {

    const headers = {
        "Content-Type": "application/json", // Change the content type to handle file upload
    };
    setLoading(true);
    
    axios.post(post_api,jsonData, headers)
        .then(function (response) {
            setLoading(false);
            if(response.data.status!=='success'){
              $('.server_error').text(response.data.msg);
            }else{ 
            // Session Storage
              
              //navigatesignin('/my_chatbots');
            }
        }).catch(function (error) {
            console.error(error);
            $('.server_error').text(error.message);
            // Handle the error here
            setLoading(false);
            // You can display an error message or perform any necessary actions
          });

    
};

  return (
    <div className="custom_qa setting">
      {/* <div>
        <Header />
      </div> */}
      <div className="custom_qa_wrapper setting_wrapper">
        <div className="custom_qa_container setting_container">
          <div>
            <SideBar />
          </div>
          <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem", borderRadius: '20px' }}>
            <div className="col-lg-10">
              <div className="custom_qa_head_container">
                <div className="custom_qa_head">
                  <div
                    className="btn sidebar_toggle_btn d-block d-lg-none"
                    id="sidebar_toggle_btn"
                  >
                    <div className="rotate_arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </div>
                  </div>
                  <h3>Custom Q & A</h3>
                </div>
                <div className="custom_qa_btn_div">
                  <button
                    className="btn custom_qa_btn"
                    data-toggle="tooltip"
                    data-bs-placement="left"
                    title="This feature is not available right now."
                    onClick={handleBtnClick}
                  >
                    <img src={isContainerVisible ? BackSvg : CustomQASvg} alt="icon" />
                    <p>{isContainerVisible ? "Back" : "Custom Q&A"}</p>
                  </button>
                </div>
              </div>
            </div>

            {isContainerVisible ? (
              <div className="add_custom_qa_container">
                <div className="add_custom_qa_inputs">
                  <div className="add_custom_question">
                    <label>Add custom question to your chatbot:</label>
                    <br />
                    <textarea
                      id="questionInput"
                      rows="4"
                      cols="50"
                      placeholder="Add your question here..."
                    ></textarea>
                  </div>
                  <div className="add_custom_answer">
                    <div className="input_div_website">
                      <label>Add custom answer for your question:</label>
                      <br />
                      <textarea
                        id="answerInput"
                        rows="4"
                        cols="50"
                        placeholder="Add your answer here..."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="update_btn_div">
                  <button className="btn update_btn" onClick={handleAddQuestion}>Add</button>
                </div>
              </div>
            ) : (
              <div className="custom_qa_text">
                <p>
                  Sometimes, AI chatbot may not be able to answer visitor's
                  inquiries, thus, it is essential to provide guidance on how to
                  address those questions.
                </p>
                <p>
                  With "Questions & Answers" feature, you can design a custom
                  collection of queries and corresponding responses on top of the
                  knowledge base that you expect the chatbot to answer when a
                  visitor asks.
                </p>
              </div>
            )}

            <div className="added_queations_wrapper">
            <form id='save_form'>
              <div className="added_queations_container">
                {questions.map((question, index) => (
                  <div className="added_queations_div" key={index}>
                    <div className="added_question_container">
                      <p className="added_question"><span>Question No. {index + 1}:</span> {question.question}</p>
                      <p className="added_answer"><span>Answer:</span> {question.answer}</p>
                    </div>
                    <div className="text-right">
                      <div className="delete_question">
                        <div className="remove_question_btn" onClick={() => handleRemoveQuestion(index)}>
                          <img src={Delete2Svg} alt="icon" />
                          <p>Remove question</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              </form>
              <div className="interface_update_btn_div">
              <button className="btn interface_update_btn" onClick={validateForm}>
                Update
              </button>
            </div>
            <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomQA;
