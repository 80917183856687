import React, { useState,useEffect } from 'react';
import '../Css/Profile.css'
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { Link,useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Success from '../Assests/images/success_gif.gif'
import $ from 'jquery';
import axios from 'axios';
import Cookies from 'js-cookie';
import { appauth_key,user_detail_by_id,profile_update,tookan_refresh_api} from '../ServiceConnection/serviceconnection.js';
import Header from './Header';
import Footer from './Footer';
function Profile() {
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [first_name_ft, setFirstNameFt] = useState('');
    const [last_name_ft, setLastNameFt] = useState('');
    const [email_ft, setEmailft] = useState('');
    const [ReadFst, setReadFst] = useState('false');
    const [Readlst, setReadlst] = useState('false');
    const [Reademail, setReadEmail] = useState('readOnly');
    const [Readpass, setReadpass] = useState('false');
    const [Readrepass, setReadrepass] = useState('false');
    const navigate = useNavigate();
    
    useEffect(() => {
        console.log(localStorage.getItem('gmail_login'));
        if(localStorage.getItem('gmail_login')=='1'){
            setReadFst('readOnly');
            setReadlst('readOnly');
            setReadrepass('readOnly');
            setReadpass('readOnly');
            $('.update_btn').addClass('disabled');
        }
        
        user_detail_with_id();
        }, []);
    
   
    const user_detail_with_id = () => {
        const headers = {
            "Authorization": `Bearer ${Cookies.get('access_token')}`,
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        axios.get(user_detail_by_id(localStorage.getItem('user_id')),{ headers: headers })
        .then(function (response) {
          const data = response.data;
          const first_name_vl = data.data.first_name;
          const last_name_vl = data.data.last_name;
          const email_vl = data.data.email;
          setFirstNameFt(first_name_vl);
          setLastNameFt(last_name_vl);
          setEmailft(email_vl);
          
        })
        .catch(function (error) {
            if(error?.response?.data?.data?.expired == '1') {tookan_refresh();}
            else if(error?.response?.data?.data?.expired == '0') { localStorage.clear(); navigate('/sign_in');}
            else{
              console.error();
            }
        });
    };    
    const tookan_refresh = () => {
        const headers = {
            "Authorization": `Bearer ${Cookies.get('refresh_token')}`,
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        console.log(Cookies.get('refresh_token'));
        const requestData = {
            // Add your request data here if needed.
        };
        axios.post(tookan_refresh_api,requestData,{ headers: headers })
          .then(function (response) {
            console.log(response)
            if(response.data.access_token!=''){
                Cookies.set('access_token', response.data.access_token); 
                user_detail_with_id(); 
            }else{
                localStorage.clear();
                navigate('/sign_in');
            }
          })
          .catch(function (error) {
            localStorage.clear();
            navigate('/sign_in');
            console.log(error)
            
          });
        
    };

    const validateForm = () => {
            var isValid = true;
            
            $(".trio_mandatory").each(function () {
                const $inputfld = $(this);
                const value = $.trim($inputfld.val());
                if (value === "") {
                    isValid = false;
                    $inputfld.css({ border: "1px solid red", width: "50px !important" });
                    if ($inputfld.next("span.err_msg").length === 0) {
                        $("<span>").addClass("err_msg").text("Please fill this field").insertAfter($inputfld);
                    }
                } else {
                    $inputfld.css({ border: "", background: "" });
                    $inputfld.next("span.err_msg").remove();
                }
            });

            if (isValid === true) {
                $('span.err_msg').text('');
                let password = document.querySelector('.password').value;
                let confirm_password = document.querySelector('.confirm_password').value;

                if (password.length < 8) {
                    isValid = false;
                    adderrorspan('password', 'Password should be at least 8 characters');
                }
                if (password !== confirm_password) {
                    isValid = false;
                    adderrorspan('confirm_password', 'Passwords do not match');
                }



                if (isValid === true) {
                    const formData = new FormData(document.getElementById('save_form')); // Get form data
                    formData.append('email',localStorage.getItem('user_email'));
                    formData.append('id',localStorage.getItem('user_id'));
                    formData.append('appauth_key',appauth_key);
                    const jsonData = {};

                    for (let [key, value] of formData.entries()) {
                        jsonData[key] = value;
                    }  
                    saving_data(jsonData); // Pass the form data to the saving_data function
                }


            }
        };


    const saving_data = async (jsonData) => {


        const headers = {
            "Authorization": `Bearer ${Cookies.get('access_token')}`,
            "Content-Type": "application/json", // Change the content type to handle file upload
        };
        setLoading(true);
        axios.patch(profile_update,jsonData,headers)
            .then(function (response) {
        setLoading(false);
                // Session Storage
                if(response.data.status!=='success'){
                    $('.server_error').text(response.data.error.message);
                }else{   
        
        setShowModal(true);
                }
            }).catch(function (error) {
                console.error(error);
                $('.server_error').text(error.message);
                // Handle the error here
                setLoading(false);
                // You can display an error message or perform any necessary actions
              });

        
    };


    const closeModal = () => {
        setShowModal(false);
    };

    const adderrorspan = (clasnm, msg) => {
        $("<span>").addClass("err_msg").text(msg).insertAfter('.' + clasnm);
        $('.' + clasnm).css({ border: "1px solid red", width: "50px !important" });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <div className='profile'>
            {/* <div>
                <Header />
            </div> */}
            <div className='profile_wrapper container-md'>
                <div className='profile_container'>
                    <h1>Profile</h1>
                    <div className='profile_form_container shadow'>
                        <form id='save_form'>
                            <div className='input_fields signup_inputs'>
                                <div className='name'>
                                    <div className='w-100'>
                                        <label>First Name:</label>
                                        <input name='first_name' readOnly={ReadFst === 'readOnly'}  value={first_name_ft} onChange={(e) => setFirstNameFt(e.target.value)}
                                            className='first_name trio_mandatory'
                                            type='text'
                                            placeholder='First Name*' />
                                    </div>
                                    <div className='w-100'>
                                        <label>Last Name:</label>
                                        <input name='last_name' readOnly={Readlst === 'readOnly'}  value={last_name_ft} onChange={(e) => setLastNameFt(e.target.value)}
                                            className='last_name trio_mandatory'
                                            type='text'
                                            placeholder='Last Name'
                                        />
                                    </div>
                                </div>
                                <div className='space_below_input'></div>
                                <div className='w-100'>
                                    <label>Email:</label>
                                    <input  readOnly={Reademail === 'readOnly'} autoComplete="off" value={email_ft} 
                                        placeholder='Email id*' className='email_id trio_mandatory'
                                    />
                                    
                                </div>
                                <div className='password_input input_div_signin mb-3'>
                                    <label>New Password:</label>
                                    <input name='password' readOnly={Readpass === 'readOnly'} autoComplete="off"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='New password*' className='password trio_mandatory'
                                    />
                                    {showPassword ? (
                                        <RiEyeOffFill
                                            className='password_icon password_toggle_icon'
                                            onClick={togglePasswordVisibility}
                                        />
                                    ) : (
                                        <RiEyeFill
                                            className='password_icon password_toggle_icon'
                                            onClick={togglePasswordVisibility}
                                        />
                                    )}
                                </div>
                                <div className='password_input input_div_signin'>
                                    <label>Confirm New Password:</label>
                                    <input name='re_password' autoComplete="off" readOnly={Readrepass === 'readOnly'}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder='Confirm password*' className='confirm_password trio_mandatory'
                                    />

                                    {showConfirmPassword ? (
                                        <RiEyeOffFill
                                            className='password_icon password_toggle_icon'
                                            onClick={toggleConfirmPasswordVisibility}
                                        />
                                    ) : (
                                        <RiEyeFill
                                            className='password_icon password_toggle_icon'
                                            onClick={toggleConfirmPasswordVisibility}
                                        />
                                    )}
                                </div>
                                <div className='space_below_input'></div>
                                <span className='server_error err_msg'></span>
                                <div className='update_btn_div text-end'>
                                    <div className='btn update_btn' onClick={validateForm}>
                                        Update
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div
                                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
                            >
                                <div className='loader'></div>
                            </div>
                    </div>
                </div>
            </div>
            <Modal id='myModal' show={showModal} onHide={closeModal} centered backdrop="static">
                <Modal.Body className='modal_body'>
                    <div className='success_img d-flex justify-content-center'>
                        <img src={Success} alt='chatbot' />
                    </div>
                    <p>Profile Updated successfully.</p>
                </Modal.Body>
                <Modal.Footer>
                        <div className='btn go_to_login' onClick={() => window.location.reload()}>Ok</div>
                    
                </Modal.Footer>
            </Modal>
            <Footer />
        </div>
    );
}

export default Profile;