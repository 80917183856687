import React, { useState,useEffect } from 'react';
import '../Css/ForgotPassword.css';
import Forgot2 from '../Assests/images/forgot_2.gif';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Success from '../Assests/images/success_gif.gif';
import $ from 'jquery';
import axios from 'axios';
import { appauth_key, forgot_passwprd_otp } from '../ServiceConnection/serviceconnection.js';
import Header from './Header';

function ForgotPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    

    useEffect(() => {
        if(localStorage.getItem('forgot_email')){
            setEmail(localStorage.getItem('forgot_email'));
        }
    },[]);


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsValid(true); // Reset validation state when email changes
    };

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const handleGetOTP = (e) => {
        e.preventDefault();

        if (validateEmail(email)) {
            // Email is valid
            // Display the modal
            //setShowModal(true);
            const jsonData = {};
            jsonData['email'] = email;
            jsonData['appauth_key'] = appauth_key;
            saving_data(jsonData);
        } else {
            // Email is invalid
            setIsValid(false);
        }
    };


    const saving_data = async (jsonData) => {
        console.log(jsonData);
        
            const headers = {
                "Content-Type": "application/json", // Change the content type to handle file upload
            };
            setLoading(true);
            
            axios.post(forgot_passwprd_otp,jsonData, headers)
                .then(function (response) {
                    setLoading(false);

                    if(response.data.status!=='success'){
                      $('.server_error').text(response.data.msg);
                    }else{ 
                    // Session Storage
                      localStorage.setItem('forgot_email',email);
                      navigate('/verify_account');
                    }
                }).catch(function (error) {
                    console.error(error);
                    $('.server_error').text(error.message);
                    // Handle the error here
                    setLoading(false);
                    // You can display an error message or perform any necessary actions
                  });
        
            
        };

    const handleCloseModal = () => {
        setShowModal(false);
        
    };

    return (
        <div className='forgot_password'>
            {/* <div>
                <Header />
            </div> */}
            <div className='forgot_password_wrapper'>
                <div className='forgot_password_container row col-xl-6 col-lg-7 col-md-9 col-sm-10 shadow m-auto p-0'>
                    <div className='left_section col-md-4'>
                        <div className='forgot_img_div'>
                            <img src={Forgot2} alt='chatboat' />
                        </div>
                    </div>
                    <div className='right_section col-md-7 m-auto'>
                        <div className='form_div col-md-9 col-10 m-auto'>
                            <h2 className='text-center'>Forgot Password?</h2>
                            <p>No worries! Just enter your email, and an OTP will be sent to your email to verify your account with us.</p>
                            <div className='input_fields'>
                                <label>Email</label>
                                <br />
                                <input type='email' placeholder='Enter your email address' value={email} onChange={handleEmailChange} />
                                {!isValid && <p className='err_msg'>Please enter a valid email address.</p>}
                            </div>
                            <span className='server_error err_msg'></span>
                            <div className='otp_btn_div text-center'>
                                <div className='btn otp_btn' onClick={handleGetOTP}>
                                    Get OTP
                                </div>
                            </div>
                            <div className='back_btn'>
                                <Link to='/sign_in'>
                                    <span>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#F58634' className='bi bi-arrow-left-circle-fill' viewBox='0 0 16 16'>
                                            <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z' />
                                        </svg>
                                    </span>
                                    <span className='goback_text'>Go back</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
              >
                <div className='loader'></div>
              </div>
            <Modal id="myModal" show={showModal} onHide={handleCloseModal} centered backdrop="static">
                <Modal.Body>
                    <div className='verify_modal'>
                        <img src={Success} alt='chatboat' />
                        <p className='text-center'>OTP sent successfully!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='close_btn' onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ForgotPassword;
