import React, { useState } from 'react';
import '../Css/CreateByText.css'

function File() {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
    };

    return (
        <>
            <div className='textarea_head d-flex justify-content-between align-items-end'>
                <div className='ml-auto mt-2'>
                    <input id='add_file' multiple onChange={handleFileSelect} type='file' className='d-none'></input>
                    <label className='btn add_file_btn' htmlFor='add_file'>+ Add Files</label>
                </div>
            </div>
            <div className='text_input'>
                <div className='file_table_div'>
                    <div className='file_table'>
                        {selectedFiles.length > 0 ? (
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>NAME</th>
                                        <th>SIZE</th>
                                        <th>CHARACTERS</th>
                                        <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody className='custom_tbody'>
                                    {selectedFiles.map((file, index) => (
                                        <tr key={index}>
                                            <td>{file.name}</td>
                                            <td>{file.size} bytes</td>
                                            <td>1345</td>
                                            <td><div className='processed'><p>Processed</p></div></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (<div className='no_file_text'>
                            <p>No File Yet.</p>
                        </div>)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default File;