import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Css/UpdateKnowledge.css";
import SideBar from "./SideBar";
import DeletSweep from "../Assests/images/delete-sweep.svg";
import TextIcon from "../Assests/images/text_icon.svg";
import FileIcon from "../Assests/images/file_icon.svg";
import WebIcon from "../Assests/images/website_icon.svg";
import SimpleSearch from "../Assests/images/simple_search.svg";
import CustomQASvg from "../Assests/images/custom_qa_svg.svg";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Success from '../Assests/images/success_gif.gif'
import { ToastContainer, toast } from 'react-toastify';
import { showErrorMsg, showSuccessMsg } from './ToastNotifications';
import 'react-toastify/dist/ReactToastify.css';
import {
  domain_urls,
  url_scraped,
  chatboat_api_update,
  chatboat_text_save,
  updateknowledge,
} from "../ServiceConnection/serviceconnection.js";
import Header from './Header'

let setStopWebCrawl = '0';
let start_for_chatbotiddata = "";
let variable_for_test = "";
function UpdateKnowledge() {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [ChatboatName, SetChatboatName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModalSave, setShowModalSave] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalData, setshowErrorModalData] = useState('Remove data sources with no data.');
  const [characterCount, setCharacterCount] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [activedatasrc, setactivedatasrc] = useState();
  const [loading, setLoader] = useState(false);
  const data_source = "";
  // const [buttonDisabled, setButtonDisabled] = useState(false);


  const [filecharacter, setFileCharacter] = useState(0);
  const [websitecharacter, setWebsiteCharacter] = useState(0);
  const [page_found, setPage_found] = useState(0);
  const [page_crawled, setPage_crawled] = useState(0);
  const [url_characters_found, setUrl_characters_found] = useState(0);
  const [textcharacter, setTextCharacter] = useState(0);
  const [totalCharacterCount, setTotalCharacterCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  // const [stopwebcrawl, setStopWebCrawl] = useState(0);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const navigate = useNavigate();
  //Website JS

  const [inputValue, setInputValue] = useState("");

  const datasoucecond = useCallback(() => {
    $(".comn_cls").addClass("hidden_tabs");
    $(".chr_cm_cls").addClass("hidden_character");
    $(".drop_cm_cls").removeClass("hidden_dropdown");
    if (dataArray.length === 1) {
      $('.remove_btn').addClass('disabled');
      //navigate("/create_chatbot_options");
    } else if (dataArray.length > 1) {
      $('.remove_btn').removeClass('disabled');

    }
    dataArray.forEach(function (value) {
      $(".comn_cls").removeClass("selected_tab");
      const dtsr = value + "_cls";
      $("." + dtsr).removeClass("hidden_tabs");
      //handleTabClick(value);
      charter_fnctn(value);
      const dtsr1 = value + "_drop_down";
      $("." + dtsr1).addClass("hidden_dropdown");
    });
    handleTabClick("");

  }, [dataArray, navigate]); // Add dependencies as needed


  const handleclickevnt = useCallback(() => {
    //console.log('yes call '+activedatasrc);
    setactivedatasrc(activedatasrc);
    variable_for_test = activedatasrc;
    setActiveTab(activedatasrc);
    let dtsrvb = activedatasrc + "_cls";
    $(".comn_cls").removeClass("selected_tab");
    $("." + dtsrvb).addClass("selected_tab");

  }, [activedatasrc]);

  useEffect(() => {
    const currentUrl = location.pathname;
    const pathnameParts = currentUrl.split('/');
    const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
    console.log('Second Value:', secondValue);
    start_for_chatbotiddata = secondValue;
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };
    axios.get(updateknowledge(start_for_chatbotiddata), headers)
      .then(function (response) {

        const data = response.data;
        let actv = '';
        const newArray = [];

        if (data.Web == 'True') {
          newArray.push('website');
          actv = 'website';
          console.log(data.webs_data);
          setUrls(data.webs_data);
          const webchrt = data.webs_data.reduce(
            (total, wb) => total + parseInt(wb.charctr),
            0
          );
          const elementsToAdd = [];
          for (let i = 0; i < data.webs_data.length; i++) {
            elementsToAdd.push(i);
          }
          setCheckedBoxes([...checkedBoxes, ...elementsToAdd]);
          setWebsiteCharacter(webchrt);
        }

        if (data.file == 'True') {
          newArray.push('file');
          actv = 'file';
          console.log(data.files_data);
          setSelectedFiles(data.files_data);

          const flschrt = data.files_data.reduce(
            (total, file) => total + file.chtrln,
            0
          );

          setFileCharacter(flschrt);
        }

        if (data.text == 'True') {
          newArray.push('text');
          actv = 'text';
          const inputText = data.texts_data[0]['content'];
          $('.textinput').val(inputText);
          setTextCharacter(inputText.length);
        }


        handledropdownClickfirst(newArray, actv);

      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    handleclickevnt();
  }, [activedatasrc]);

  const handleTabClick = (tab) => {
    ;
    if (tab !== "") {
      // console.log(tab);
      setactivedatasrc(tab);
    } else {
      let dtsrvb = variable_for_test + "_cls";
      $("." + dtsrvb).addClass("selected_tab");
    }
  };



  const handledropdownClick = (tab) => {
    // console.log(tab+" "+"dddclick" )
    setactivedatasrc(tab);
    variable_for_test = tab;
    console.log(variable_for_test);
    const updatedArray = [...dataArray, tab];
    console.log(updatedArray);
    const dynma = '.' + tab + '_chractr';
    $(dynma).addClass('plain_text');
    setDataArray(updatedArray);
    console.log(dataArray);
  };

  const handledropdownClickfirst = (tab, actv) => {
    setactivedatasrc(actv);
    const updatedArray = tab;
    const dynma1 = '.text_chractr';
    $(dynma1).addClass('plain_text');

    const dynma = '.file_chractr';
    $(dynma).addClass('plain_text');
    setDataArray(updatedArray);
  };
  const charter_fnctn = (value) => {
    const chrt_h = value + "_chractr";
    $("." + chrt_h).removeClass("hidden_character");
  };


  

  useEffect(() => {
    // if (!data_source) {
    //   navigate("/create_chatbot_options");
    // } else {
    datasoucecond();
    setTotalCharacterCount(filecharacter + textcharacter + websitecharacter);

    ['text', 'file', 'website'].forEach(className => {
      let charactering;
      if (className === 'text') {
        charactering = textcharacter;
      } else if (className === 'file') {
        charactering = filecharacter;
      } else if (className === 'website') {
        charactering = websitecharacter;
      }
      const dynma = '.' + className + '_chractr';
      const dynma_snd = '.' + className + '_cls';
      if (charactering < 11 && !$(dynma).hasClass('hidden_character')) {
        $(dynma).addClass('plain_text');
      } else {
        $(dynma).removeClass('plain_text');
      }
    });
    // }
  }, [filecharacter, textcharacter, websitecharacter, dataArray, datasoucecond, data_source, navigate]); // Add dependencies as needed

  //Remove Data Souce
  const removedatasource = () => {
    const updatedArray = dataArray.filter((data) => data !== activedatasrc);
    handleTabClick(updatedArray[0]);
    setDataArray(updatedArray);
    // console.log('delete done');
    if (activedatasrc === 'text') {
      setTextCharacter(0);
      $('.textinput').val('');
      $('.text_chractr').removeClass('plain_text');
    } else if (activedatasrc === 'file') {
      setSelectedFiles('');
      setFileCharacter(0);
      $('.file_chractr').removeClass('plain_text');
    } else if (activedatasrc === 'website') {
      setStopWebCrawl = '1';
      // console.log(setStopWebCrawl);
      setUrls('');
      setWebsiteCharacter(0);
      setCheckedBoxes('');
      setPage_found(0);
      setPage_crawled(0);
      setUrl_characters_found(0);
      $('.website_chractr').removeClass('plain_text');
    }
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowErrorModal(false);
  };
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const TextKeyup = (event) => {
    const inputText = event.target.value;
    setTextCharacter(inputText.length);
  };


  const filelist = (event, chtrln) => {
    const files = Array.from(event.target.files);
    const fileData = files.map((file) => {
      let fileSize = (file.size / (1024 * 1024)).toFixed(2); // Convert to MB

      if (fileSize === "0.00") {
        // If size is 0.00 MB, convert to KB
        fileSize = (file.size / 1024).toFixed(2) + " KB";
      } else {
        fileSize += " MB";
      }
      return {
        name: file.name,
        size: fileSize,
        type: file.type,
        lastModified: file.lastModified,
        chtrln: chtrln,
        evnt: event.target.files,
        id: '0',
      };
    });
    // console.log(fileData);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...fileData]);
    setFileCharacter((prevCount) => prevCount + chtrln);
  };


  const handleDeleteFile = (index) => {
    // console.log(index);
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      const deletedFile = updatedFiles.splice(index, 1)[0]; // Remove the file at the specified index
      const deletedCharacterCount = deletedFile.chtrln; // Get the character count of the deleted file
      setFileCharacter((prevCount) => prevCount - deletedCharacterCount); // Subtract the character count of the deleted file from the total count

      return updatedFiles;
    });
  };




  const save_chatboat = () => {
    const headers = {
      "Content-Type": "application/json", // Change the content type to handle file upload
    };


    let data_source_update = '';
    let stop_save = '0';

    ['text', 'file', 'website'].forEach(className => {
      let charactering;
      if (className === 'text') {
        charactering = textcharacter;
      } else if (className === 'file') {
        charactering = filecharacter;
      } else if (className === 'website') {
        charactering = websitecharacter;
      }
      const dynma = className + '_chractr';
      if ((charactering) >= 11) {
        if (data_source_update === '') {
          data_source_update = className;
        } else {
          data_source_update = data_source_update + ',' + className;
        }

      }

      const o = $('.' + dynma).hasClass('plain_text');
      if (o) {
        stop_save = '1';
      }


    });

    if (stop_save === '1') {
      setshowErrorModalData('Remove data sources with no data.');
       setShowErrorModal(true);
       return;
     }
     
    // Create a new FormData object
    const formData = new FormData();
    formData.append("chatboat_id", start_for_chatbotiddata);
    formData.append("data_source", data_source_update);
    formData.append("plan_types", '1');

    if (textcharacter > 0) {
      formData.append("text", 'True');
      formData.append("text_content", $('.textinput').val());
    } else {
      formData.append("text", 'False');
    }
    console.log(selectedFiles.length);
    if (selectedFiles.length > 0) {
      let olid = 0;
      formData.append("file", 'True');
      selectedFiles.forEach((file) => {
        if (file.id == '0') {
          formData.append("files[]", file.evnt[0]);
        } else {
          console.log(file);
          const fileBlob = new Blob([JSON.stringify(file)], { type: "application/json" });
          formData.append("files_old[]", fileBlob);
        }
      });
    } else {
      formData.append("file", 'False');
    }

    if (websitecharacter > 0) {
      formData.append("Web", 'True');
      const datanyo = checkedBoxes.map((chkbx_lop, indexn) => {
        console.log(urls[chkbx_lop]);
        formData.append("weburl[]", JSON.stringify(urls[chkbx_lop]));
      });
    } else {
      formData.append("Web", 'False');
    }
    console.log(formData);
    save_chatboat_final(formData);
    return '1';
    const jsonData = {};
    jsonData["content"] = $('.textinput').val();
    axios
      .post(chatboat_text_save, jsonData, headers)
      .then(function (response) {
        // console.log(response);
        // console.log(response.data);

        // setShowModalSave(true);

        // Session Storage 
        if (response.data.status !== "success") {
          setLoading(false);
          $(".server_error").text(response.data.error.message);
        } else {
          const jsonDatanew = {};
          jsonDatanew["plan_types"] = '1';
          jsonDatanew["chatbot_name"] = $('.chatboat_name').val();
          jsonDatanew["customer_name"] = localStorage.getItem('user_id');
          jsonDatanew["file"] = '1';
          jsonDatanew["text"] = response.data.data.id;
          jsonDatanew["Web"] = '1';
          // console.log(jsonDatanew);
          save_chatboat_final(jsonDatanew);
        }
      })
      .catch(function (error) {
        //setShowModalSave(true);
        //console.error(error);
        /* if(error.response.data.msg){
            $('.server_error').text(error.response.data.msg);
            }else{
                $('.server_error').text(error.message);
            } */
        // Handle the error here
        setLoading(false);
        // You can display an error message or perform any necessary actions
      });

  };



  const save_chatboat_final = (jsonDatanew) => {
    // const headers = {
    //   "Content-Type": "application/json", // Change the content type to handle file upload
    // };
    // setLoading(true);
    setLoading(true);
    axios.defaults.withCredentials = true
    const csrftoken = getCookie('csrftoken');
    axios.post(chatboat_api_update, jsonDatanew, {
      headers: {
        'X-CSRFToken': csrftoken // Include the CSRF token in the header
      },
    })
      .then(function (response) {
        setLoading(false);
        if (response.data.status != 'success') {
          showErrorMsg(response.data.message);
        } else {
          //showSuccessMsg();
          setShowSuccessModal(true);
          // window.location.reload();
          //navigate('/my_chatbots/'+start_for_chatbotiddata+'/update_knowledge');
        }
      })
      .catch(function (error) {
        showErrorMsg(error.message);
        setLoading(false);
      });
  };

  

  const closeModal1 = () => {
    setShowSuccessModal(false);
    window.location.reload();
    //navigate("/my_chatbots");
  };

  const closeModal = () => {
    setShowModalSave(false);
    window.location.reload();
    //navigate("/my_chatbots");
  };
  const addUrl = () => {
    if (inputValue.trim() === "") {
      alert("Please enter a URL.");
      return;
    }
    $('.crawl_btn').addClass('disabled');
    $('.save_chatboat').addClass('disabled');
    // console.log(inputValue);
    setLoader(true);
    //setButtonDisabled(true);
    setUrls('');
    setWebsiteCharacter(0);
    setCheckedBoxes('');
    setPage_found(0);
    setPage_crawled(0);
    setUrl_characters_found(0);
    const jsonData = {
      flg: "1",
      domain: inputValue,
    };
    // https://ekonacademy.com/
    setStopWebCrawl = '0';//crawl start 
    axios.defaults.withCredentials = true
    const csrftoken = getCookie('csrftoken');
    axios.post(domain_urls, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken // Include the CSRF token in the header
      },
    })
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data);

      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        $('.crawl_btn').removeClass('disabled');
        setLoader(false);
      });
  };
  // Function to get the CSRF token from the cookie
  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }

  const url_showfn = (data) => {

    if (setStopWebCrawl == 0) {
      const urlsArray = [
        { flg: '2', url: data.current_scraped, charctr: data.lengthvl, charctr_text: data.content, status: data.status, id: 0 }
      ];

      // console.log(urlsArray);
      setUrls((prevSelectedUrls) => [...prevSelectedUrls, ...urlsArray]);
      setPage_found((prevCount) => prevCount + 1);
      setPage_crawled((prevCount) => prevCount + 1);
      setUrl_characters_found((prevCount) => prevCount + data.lengthvl);
      if (data.next_scraped == 'done') {
        $('.crawl_btn').removeClass('disabled');
        $('.save_chatboat').removeClass('disabled');
        setLoader(false);
      } else { //url scrapped done
        addUrl2();
      }
    } else {
      $('.crawl_btn').removeClass('disabled');
      $('.save_chatboat').removeClass('disabled');
      setLoader(false);
    }
  };


  const addUrl2 = () => {


    axios.defaults.withCredentials = true;
    axios
      .get(domain_urls)
      .then(function (response) {
        const data = response.data.data;
        url_showfn(data);
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        console.error(error);
        $('.crawl_btn').removeClass('disabled');
        setLoader(false);
      });
  };




  const CheckUncheckBox = (index) => {
    // Check if the checkbox is already checked
    const updatedwebchr = [...urls];
    const slchk = updatedwebchr.splice(index, 1)[0]; // Remove the file at the specified index
    // console.log(slchk.charctr);
    const chtrlnts = parseInt(slchk.charctr); // Get the character count of the deleted file
    if (checkedBoxes.includes(index)) {
      // Checkbox is already checked, so uncheck it
      setCheckedBoxes(checkedBoxes.filter((item) => item !== index));
      setWebsiteCharacter((prevCount) => prevCount - chtrlnts);
    } else {
      // Checkbox is unchecked, so check it
      setCheckedBoxes([...checkedBoxes, index]);
      setWebsiteCharacter((prevCount) => prevCount + chtrlnts);
    }
  };

  // const deleteUrl = (index) => {
  //   const updatedUrls = urls.filter((_, i) => i !== index);
  //   setUrls(updatedUrls);
  // };



  // const setActiveTabStyling = (tab) => {
  //   $(".icon_text_div").removeClass("active");
  //   $(`#${tab}`).addClass("active");
  // };

  const countCharactersbyfile = async (event) => {
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === "txt") {
      textfilecount(event);
    } else if (extension === "pdf") {
      countCharacters(event);
    }
  };
  const countCharacters = async (event) => {
    const file = event.target.files[0];
    const eventns = event;

    const pdfjsLib = await import("pdfjs-dist/build/pdf");

    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

    const fileReader = new FileReader();
    fileReader.onload = async function (event) {
      const typedArray = new Uint8Array(event.target.result);
      const loadingTask = pdfjsLib.getDocument(typedArray);

      try {
        const pdf = await loadingTask.promise;

        let totalCharacters = 0;

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const text = content.items.map((item) => item.str).join("");
          //console.log(text);
          totalCharacters += text.length;
        }

        setCharacterCount(totalCharacters);
        filelist(eventns, totalCharacters);
      } catch (error) {
        console.error(error);
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  const textfilecount = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        const count = contents.length;
        filelist(event, count);
        setCharacterCount(count);
      };

      reader.readAsText(file);
    }
  };


  /*   const [stopLoop, setStopLoop] = useState(false);
  
    const startLoop = () => {
      // Reset the stopLoop flag before starting the loop
      setStopLoop(false);
  
      for (let i = 1; i <= 10000; i++) {
        // console.log('Loop iteration:', i);
  
        // Perform your desired actions within the loop here
  
        // Check the stopLoop flag
        if (stopLoop) {
          // console.log('Loop stopped.');
          break;
        }
      }
    };
  
    // console.log(startLoop());
   */
  // const stopLoopExecution = () => {
  //   setStopLoop(true);
  // };


  return (
    <div className="update_knowledge setting">
      {/* <Header /> */}
      <div className="update_knowledge_wrapper setting_wrapper">
        <div className="update_knowledge_container setting_container">
          <div>
            <SideBar />
          </div>
          <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem" }}>
            <div className="chatbox_head_left">
              <div
                className="btn sidebar_toggle_btn d-block d-lg-none"
                id="sidebar_toggle_btn"
              >
                <div className="rotate_arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-chevron-double-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
              <h2>Update Knowledge</h2>
            </div>

            <div className="update_knowledge_tabs_container">
              <div className="update_knowledge_tabs">
                <div
                  className={`icon_text_div d-flex align-items-center text_cls comn_cls `}
                  onClick={() => handleTabClick("text")}
                >
                  <img src={TextIcon} alt="icon" />
                  <h5>Text</h5>
                </div>
                <div
                  className={`icon_text_div d-flex align-items-center file_cls comn_cls `}
                  onClick={() => handleTabClick("file")}
                >
                  <img src={FileIcon} alt="icon" />
                  <h5>File</h5>
                </div>
                <div
                  className={`icon_text_div d-flex align-items-center website_cls comn_cls`}
                  onClick={() => handleTabClick("website")}
                >
                  <img src={WebIcon} alt="icon" />
                  <h5>Website</h5>
                </div>
              </div>
              <div className="data_source_btn_div d-none d-sm-block">
                <button
                  className="btn add_more_btn dropdown-toggle shadow"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  + Add more data source
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item drop_cm_cls f text_drop_down"
                    onClick={() => handledropdownClick("text")}
                  >
                    <img src={TextIcon} alt="icon" />
                    <p>Text</p>
                  </div>
                  <div
                    className="dropdown-item drop_cm_cls file_drop_down"
                    onClick={() => handledropdownClick("file")}
                  >
                    <img src={FileIcon} alt="icon" />
                    <p>File</p>
                  </div>
                  <div
                    className="dropdown-item drop_cm_cls website_drop_down"
                    onClick={() => handledropdownClick("website")}
                  >
                    <img src={WebIcon} alt="icon" />
                    <p>Website</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab_border w-100"></div>

            <div className="d-flex justify-content-end d-block d-sm-none mt-4">
              <div className="data_source_btn_div">
                <button
                  className="btn add_more_btn dropdown-toggle shadow"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  + Add more data source
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="dropdown-item drop_cm_cls f text_drop_down"
                    onClick={() => handledropdownClick("text")}
                  >
                    <img src={TextIcon} alt="icon" />
                    <p>Text</p>
                  </div>
                  <div
                    className="dropdown-item drop_cm_cls file_drop_down"
                    onClick={() => handledropdownClick("file")}
                  >
                    <img src={FileIcon} alt="icon" />
                    <p>File</p>
                  </div>
                  <div
                    className="dropdown-item drop_cm_cls website_drop_down"
                    onClick={() => handledropdownClick("website")}
                  >
                    <img src={WebIcon} alt="icon" />
                    <p>Website</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="update_tabs_pages">
              {/* Text Tab */}
              <div
                className={`text_tab ${activeTab === "text" ? "display_block_important" : ""
                  }`}
              >
                <div className="text_input">
                  <textarea onKeyUp={TextKeyup} className='textinput' placeholder="Piece of information, can be company info, book content..."></textarea>
                </div>
              </div>
              {/* File Tab */}
              <div
                className={`file_tab ${activeTab === "file" ? "display_block_important" : ""
                  }`}
              >
                <div className="textarea_head d-flex justify-content-between align-items-end">
                  <div className="ml-auto mt-2">
                    <input
                      id="add_file"
                      multiple
                      accept=".txt, .pdf"
                      onChange={countCharactersbyfile}
                      type="file"
                      className="d-none"
                    ></input>
                    <label className="btn add_file_btn" htmlFor="add_file">
                      + Add Files
                    </label>
                  </div>
                </div>
                <div className="text_input file_input">
                  <div className="file_table_div input_body_style">
                    <div className="file_table">
                      {selectedFiles.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>NAME</th>
                              <th>SIZE</th>
                              <th>CHARACTERS</th>
                              <th>STATUS</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="custom_tbody">
                            {selectedFiles.map((file, index) => (
                              <tr key={index}>
                                <td>{file.name}</td>
                                <td>{file.size}</td>
                                <td>{file.chtrln}</td>
                                <td>
                                  <div className="processed">
                                    <p>Processed</p>
                                  </div>
                                </td>
                                <td className="css-aq1g2j">
                                  <div
                                    className="delete_icon"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <img src={DeletSweep} alt="icon" />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="no_file_text">
                          <p>No File Yet.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Website Tab */}
              <div
                className={`website_tab ${activeTab === "website" ? "display_block_important" : ""
                  }`}
              >
                <div className="text_input website_input">
                  <div className="website_container input_body_style">
                    <h5 className="url_label">
                      Website Url:<span>*</span>
                    </h5>
                    <div className="url_input">
                      <input
                        type="url"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="website must start with https://"
                      />
                      <div
                        className={`btn crawl_btn ${inputValue.trim() === "" ? "disabled" : ""}`}
                        onClick={addUrl}
                      >

                        <span>
                          {loading ? (
                            <div className="loader_round"></div>
                          ) : (
                            <>
                              <span><img src={SimpleSearch} alt="icon" /></span>
                              <span className="crawl_text">Crawl</span>
                            </>
                          )}
                        </span>

                      </div>
                    </div>

                    {urls.length > 0 && (
                      <div className="url_table">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                {/* <input type="checkbox" /> */}
                              </th>
                              <th className="page_url">PAGE URL</th>
                              <th>STATUS</th>
                              <th>CHARACTER</th>
                              {/* <th>DELETE</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {urls.map((url, index) => (
                              <tr key={index}>
                                <td>
                                  {url.status == '200' ? (

                                    <input
                                      type="checkbox"
                                      checked={checkedBoxes.includes(index)}
                                      onChange={() => CheckUncheckBox(index)}
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>{url.url}</td>
                                <td>
                                  {url.status == '200' ? (
                                    <div className="processed">
                                      <p>200</p>
                                    </div>
                                  ) : (
                                    <div className="notprocessed">
                                      <p>500</p>
                                    </div>
                                  )}
                                </td>
                                <td>{url.charctr}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="table_footer">
                    <table className="w-100 text-center tabel_footer_update_knowledge">
                      <tr className="footer_row">
                        <td className="tabel_footer_update_knowledge">{page_found} Pages found</td>
                        <td className="tabel_footer_update_knowledge">{page_crawled} Pages crawled</td>
                        <td className="tabel_footer_update_knowledge">{url_characters_found} Characters found</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>


              <div className="remove_data_source">
                <div className="btn remove_btn" onClick={handleModalOpen}>
                  <img src={DeletSweep} alt="icon" />
                  <p>Remove this Data Source</p>
                </div>
              </div>
              <div className="p-4">
                <div className="create_chatbot_footer">
                  <div className="create_chatbot_footer_container">
                    <div className="left_div">
                      <div className="text_counter_div">
                        <p className="txtclass chr_cm_cls text_chractr">
                          Plain Text: {textcharacter} characters
                        </p>
                        <p className="txtclass chr_cm_cls file_chractr">
                          File Text: {filecharacter} characters
                        </p>
                        <p className="txtclass chr_cm_cls website_chractr">
                          Website Text: {websitecharacter} characters
                        </p>
                      </div>
                    </div>
                    <div className="right_div"></div>
                  </div>
                  <div className="delet_build_btns">
                    <div className="total_char_div">
                      <p className="total_text m-0">Total characters: {totalCharacterCount}</p>
                    </div>
                    <div className="btn update_knowledge_btn save_chatboat" onClick={save_chatboat}>
                      <img src={CustomQASvg} alt="icon" />
                      <p>Update Knowledge</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
          <Modal.Title style={{ paddingLeft: "1rem" }}>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            Are you sure you want to remove this data source?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            className="confirm_btn"
            variant="primary"
            onClick={removedatasource}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showErrorModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >

        <Modal.Body style={{ paddingLeft: "2rem" }}>
          <p style={{ fontWeight: "500" }}>
            Remove data sources with no data.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel_btn"
            variant="secondary"
            onClick={handleModalClose}
          >
            Ok
          </Button>

        </Modal.Footer>
      </Modal>


      <Modal id='myModal' show={showSuccessModal} onHide={closeModal} centered >
        <Modal.Body className='modal_body'>
          <div className='success_img d-flex justify-content-center'>
            <img src={Success} alt='icon' />
          </div>
          <p>Successfully Updated.</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn go_to_login' onClick={closeModal1}>Ok</div>

        </Modal.Footer>
      </Modal>

      {/* <div
        className={isLoading1 ? 'loader-overlay show' : 'loader-overlay'}
      >
        <div className='loader'></div>
      </div> */}
      <div
        className={isLoading ? 'loader-overlay show' : 'loader-overlay'}
      >
        <div className='loader'></div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UpdateKnowledge;