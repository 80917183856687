import React from 'react';
import '../Css/CreateChatbotOptions.css'
import TextIcon from '../Assests/images/text_icon.svg'
import FileIcon from '../Assests/images/file_icon.svg'
import WebIcon from '../Assests/images/website_icon.svg'
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function CreateChatbotOptions() {
    const navigate = useNavigate();
    localStorage.setItem('data_source', '');
    const navigatetopage = (data_source) => {
        localStorage.setItem('data_source', data_source);
        navigate('/create_chatbot01');
    }
    return (
        <div className='create_options'>
            {/* <div>
                <Header />
            </div> */}
            <div className='create_options_wrapper'>
                <div className='create_options_container container-lg'>
                    <h1>Create Chatbot</h1>
                    <div className='options'>
                        <div className='options_wrapper'>
                            <h2 className='options_heading'>Select one data source to begin with:</h2>
                            <div className='options_container row'>
                                <div className='col-sm-3 col-8 m-auto'>
                                    <div className='create_option' onClick={() => navigatetopage('text')}>
                                        <div className='option_icon'>
                                            <img src={TextIcon} alt='icon' />
                                        </div>
                                        <h2>Text</h2>
                                    </div>
                                </div>
                                <div className=' col-sm-3 col-8 m-auto'>
                                    <div className='create_option' onClick={() => navigatetopage('file')}>
                                        <div className='option_icon'>
                                            <img src={FileIcon} alt='icon' />
                                        </div>
                                        <h2>File</h2>
                                    </div>
                                </div>
                                <div className=' col-sm-3 col-8 m-auto'>
                                    <div className='create_option' onClick={() => navigatetopage('website')}>
                                        <div className='option_icon'>
                                            <img src={WebIcon} alt='icon' />
                                        </div>
                                        <h2>Website</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='create_options_footer_div'>
                <Footer />
            </div>
        </div>
    );
}

export default CreateChatbotOptions