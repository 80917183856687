import React, { useEffect, useCallback, useRef, useState } from 'react';
import '../Css/Header.css'
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ChatBoat from '../Assests/images/logoNew.png'
import ProfileSvg from '../Assests/images/profile_svg.svg'
import SignOutSvg from '../Assests/images/signout_svg.svg'
import SubscriptionsSvg from '../Assests/images/subscriptions_svg.svg'
import ChatSvg from '../Assests/images/chat_svg.svg'
import { GoogleLogin,GoogleLogout,useGoogleLogout } from 'react-google-login';
function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const user_email = localStorage.getItem('user_email');
    const frt_name = localStorage.getItem('frt_name');
    const forgot_otp_very = localStorage.getItem('forgot_otp_very');
    const clientId = "582127068212-s1ir785jv38kv7qa44o5oorutm5tksv2.apps.googleusercontent.com"
    


    const logout = () => {
        if(localStorage.getItem('gmail_login')=='1'){
            signOut();
            console.log('google out');
        }
        localStorage.clear();
        navigate('/sign_in');
    };


    const onLogoutFailure = (error) => {
        console.log('Logout Error:', error);
    };

    const onLogoutSuccess = () => {
        
    };

    const { signOut } = useGoogleLogout({
        clientId,
        onLogoutSuccess,
        onFailure: onLogoutFailure,
      });




    const redirectifotpnotset = useCallback(() => {
        if (!forgot_otp_very){
            navigate('/sign_in');
        }
    }, [forgot_otp_very, navigate]);
    
    
    const redirectiflogin = useCallback(() => {
        if (user_email) {
            navigate('/');
        }
    }, [user_email, navigate]);

    const redirectifnotlogin = useCallback(() => {
        if (!user_email) {
            navigate('/sign_in');
        }
    }, [user_email, navigate]);



    // Check if the current path is "/verify_email"
    const [isVerifyEmailActive, SetisVerifyEmailActive] = useState(false);

    useEffect(() => {
        const currentUrl = location.pathname;

        const pathnameParts = currentUrl.split('/');
        if (pathnameParts[1] == "verify_email") {
            SetisVerifyEmailActive(true)
        }
        if (
            currentUrl === '/sign_in' ||
            currentUrl === '/sign_up' ||
            currentUrl === '/forgot_password' ||
            currentUrl === '/verify_account' ||
            currentUrl === '/reset_password'
        ) {
            redirectiflogin();
        } else if (currentUrl === '/my_profile' || currentUrl === '/subscriptions') {
            redirectifnotlogin();
        }
        
        if (currentUrl === '/reset_password') {
            redirectifotpnotset();
        }
    }, [location.pathname, redirectiflogin, redirectifnotlogin]);






    return (
        <div className={`header${isVerifyEmailActive ? ' hidden_header' : ''}`}>
            <div className='header_wrapper'>
            <div style={{ display: 'none' }}>
                    
            </div>
                <div className='header_container'>
                    <nav className="navbar navbar-expand-lg nav_bg shadow">
                        <a className="navbar-brand" href='/'>
                            <div className='logo_div'>
                                <img src={ChatBoat} alt="Brand Logo" />
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-lg-0 mt-4">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">How it works</a>
                                </li>
                                {/*<li className="nav-item">
                                    <Link className="nav-link" to="/friends_chatbot">Affiliate</Link>
    </li>*/}
    


                                <li className="nav-item">
                                    <Link className="nav-link" to="/pricing">Pricing</Link>
                                </li>
                                <li className="nav-item faq">
                                    <Link className="nav-link" to='/faqs'>FAQS</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/privacy_policy">Privacy Policy</Link>
    </li>
                                {user_email ? (
                                    <li className="nav-item d-lg-flex align-items-center">
                                        <div className="btn-group dropdown_container">
                                            <button type="button" className="btn profile_menu dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className='profile_img mr-2'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                    </svg>
                                                </div>
                                                <span className='mr-2'>{frt_name}</span>
                                            </button>
                                            <div className="dropdown-menu dropdown_menu_style dropdown-menu-right shadow">
                                                <div className='triangle_pointer'></div>
                                                <button className="dropdown-item dropdown_style" type="button">
                                                    <Link to='/my_chatbots'>
                                                        <img src={ChatSvg} alt='icon' />
                                                        <span>All Chatbots</span>
                                                    </Link>
                                                </button>
                                                <hr />
                                                <button className="dropdown-item dropdown_style mb-1" type="button">
                                                    <Link to='/my_profile'>
                                                        <img src={ProfileSvg} alt='icon' />
                                                        <span>Profile</span>
                                                    </Link>
                                                </button>
                                                <button className="dropdown-item dropdown_style" type="button">
                                                    <Link to='/subscriptions'>
                                                        <img src={SubscriptionsSvg} alt='icon' />
                                                        <span>Subscriptions</span>
                                                    </Link>
                                                </button>
                                                <hr />
                                                <button onClick={logout} className="dropdown-item dropdown_style" type="button">
                                                    <img src={SignOutSvg} alt='icon' />
                                                    <span>Sign Out</span>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    <li className="nav-item d-lg-flex align-items-center">
                                        <div className='login'>
                                            <Link className="nav-link" to='/sign_in'>Log In</Link>
                                        </div>
                                        <div className='sign_up_btn'>
                                            <Link className="btn nav-link" to='/sign_up'>Sign Up</Link>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Header;