import React, { useState,useEffect } from "react";
import "../Css/Settings.css";
import SideBar from "./SideBar";
import BasicSetting from "./BasicSetting";
import AddToWebsite from "./AddToWebsite";
import Notification from "./Notification";
import DeletePage from "./DeletePage";
import Interface from "./Interface";
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { appauth_key,chatboat_by_id } from '../ServiceConnection/serviceconnection.js';
import Header from "./Header";
let start_for_chatbotiddata="";
function Setting() {
  const [chatbotdata, SetChatbotData] = useState([]);
  const [activeTab, setActiveTab] = useState("basic");
  const location = useLocation();
  useEffect(() => {
    const currentUrl = location.pathname;
        const pathnameParts = currentUrl.split('/');
        const secondValue = pathnameParts.length >= 3 ? pathnameParts[2] : '';
        start_for_chatbotiddata=secondValue;
    const headers = {
        "Content-Type": "application/json", // Change the content type to handle file upload
    };
    axios.get(chatboat_by_id(start_for_chatbotiddata),headers)
      .then(function (response) {
        const data = response.data;
        const setng = data.data;

        const jsonData = {};
        // jsonData["chatbot_id"] = setng.Chatbot_id;
        jsonData["chatbot_id"] = setng.Chatbot_id;
        jsonData["chatbot_name"] = setng.Name;
        jsonData["accessibility"] = setng.Accessibility;
        jsonData["Remove_Branding"] = setng.Remove_Branding;
        jsonData["AI_model"] = setng.AI_model;
        jsonData["chatbot_role"] = setng.Chatbot_role;
        jsonData["collect_visitor_information"] = setng.Collect_visitor_information;
        jsonData["Ask_for_Name"] = setng.Ask_for_Name;
        jsonData["Ask_for_Email"] = setng.Ask_for_Email;
        jsonData["Ask_for_Phone"] = setng.Ask_for_Phone;
        jsonData["Heading"] = setng.Heading;
        jsonData["SubHeading"] = setng.SubHeading;
        jsonData["Image"] = setng.Image;
        jsonData["Text"] = setng.Text;
        jsonData["Background"] = setng.Background;
        jsonData["Input_Placeholder"] = setng.Input_Placeholder;
        jsonData["First_Message"] = setng.First_Message;
        jsonData["User_Message_text"] = setng.User_Message_text;
        jsonData["User_Message_background"] = setng.User_Message_background;
        jsonData["Bot_Message_text"] = setng.Bot_Message_text;
        jsonData["Bot_Message_background"] = setng.Bot_Message_background;
        jsonData["Chat_Bubble_Image"] = setng.Chat_Bubble_Image;
        jsonData["Chat_Bubble_Background"] = setng.Chat_Bubble_Background;
        jsonData["Embed_on_Website"] = setng.Embed_on_Website;
        jsonData["Email_Notification"] = setng.Email_Notification;
        // jsonData["when"] = 'customer wants to book an appointment, customer wants someone to call back.';
        // jsonData["system_prompt"] = '';
        jsonData["Email_Id"] =setng.Email_Id;
        jsonData["Chat_Transcript"] =setng.Chat_Transcript;
        jsonData["New_Contact_Information"] =setng.New_Contact_Information;
        jsonData["temperature"] =setng.temperature;
        jsonData["Image"] =setng.Image;
        jsonData["branding"] =setng.branding;
        jsonData["contact_data"] =setng.contact_data;
        jsonData["Gpt_type"] =setng.Gpt_type;
        jsonData["Image"] =setng.Image;
        SetChatbotData(jsonData);
        console.log(jsonData)
      })
      .catch(function (error) {
        console.error(error);
        const jsonData = {};

        jsonData["chatbot_id"] = start_for_chatbotiddata;
        SetChatbotData(jsonData);
      });
    }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="setting">
      {/* <div>
        <Header />
      </div> */}
      <div className="setting_wrapper">
        <div className="setting_container">
          <div>
            <SideBar />
          </div>
          <div className="w-100 mr-auto ml-0" style={{ marginTop: "2rem" }}>
            <div className="chatbox_head_left">
              <div
                className="btn sidebar_toggle_btn d-block d-lg-none"
                id="sidebar_toggle_btn"
              >
                <div className="rotate_arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-chevron-double-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
              <h2>Settings</h2>
            </div>

            <div className="setting_tabs">
              <div onClick={() => handleTabClick("basic")}>
                <p className={activeTab === "basic" ? "active_tab" : ""}>
                  Basic
                </p>
              </div>
              <div onClick={() => handleTabClick("interface")}>
                <p className={activeTab === "interface" ? "active_tab" : ""}>
                  Interface
                </p>
              </div>
              <div onClick={() => handleTabClick("addToWebsite")}>
                <p className={activeTab === "addToWebsite" ? "active_tab" : ""}>
                  Add to website
                </p>
              </div>
              <div onClick={() => handleTabClick("notification")}>
                <p className={activeTab === "notification" ? "active_tab" : ""}>
                  Notification
                </p>
              </div>
              <div onClick={() => handleTabClick("delete")}>
                <p className={activeTab === "delete" ? "active_tab" : ""}>
                  Delete
                </p>
              </div>
            </div>
            <div className="tab_border"></div>
            <div className="tab_pages">
              {activeTab === "basic" && <BasicSetting chatbotdata={chatbotdata}/>}
              {activeTab === "interface" && <Interface chatbotdata={chatbotdata}/>}{" "}
              {activeTab === "addToWebsite" && <AddToWebsite chatbotdata={chatbotdata}/>}
              {activeTab === "notification" && <Notification chatbotdata={chatbotdata}/>}
              {activeTab === "delete" && <DeletePage chatbotdata={chatbotdata}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
