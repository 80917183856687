import React from 'react'
import '../Admin-Css/AdminSidebar.css'
import { Link, useLocation } from "react-router-dom";
import DashboardSvg from "../../Assests/images/dashboardsvg2.svg";
import WebsiteSvg from "../../Assests/images/website_svg.svg";
import EarningsSvg from "../../Assests/images/earnings.svg";
import AffiliateSvg from "../../Assests/images/affiliatesvg.png";
import AdminLogOutSvg from '../../Assests/images/admin_logout.svg';
import SubscriberSvg from '../../Assests/images/subscriber.svg';
import ProfileSvg from '../../Assests/images/profile_svg2.svg';

function AdminSidebar() {
    const location = useLocation();
    const logout = () => {
        localStorage.clear();
        //navigate('/admin_login');
        window.location.href = '/admin_login';
    };
    return (
        <div className="sidebar admin_sidebar shadow">
            <div className="sidebar_wrapper">
                <div className="sidebar_container">
                    <div className="admin_sidebar_body sidebar_body">
                        <div className="admin_sidebar_body_container sidebar_body_container">
                            <ul>
                                <li
                                    className={location.pathname === "/admin_dashboard" ? "active" : ""}
                                >
                                    <Link to="/admin_dashboard">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={DashboardSvg} alt="icon" />
                                            <p>Dashboard</p>
                                        </div>
                                    </Link>
                                </li>
                                <li className={location.pathname === "/admin_conversation" ? "active" : ""}>
                                    <Link to="/admin_conversation">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={WebsiteSvg} alt="icon" />
                                            <p>Conversation</p>
                                        </div>
                                    </Link>
                                </li>
                                <li
                                    className={location.pathname === "/admin_subscriber" ? "active" : ""}
                                >
                                    <Link to="/admin_subscriber">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={SubscriberSvg} alt="icon" />
                                            <p>Subscription</p>
                                        </div>
                                    </Link>
                                </li>
                                {/*
                                <li
                                    className={
                                        location.pathname === "/admin_earnings" ? "active" : ""
                                    }
                                    style={{pointerEvents:'none'}}
                                >
                                    <Link to="/admin_earnings">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={EarningsSvg} alt="icon" />
                                            <p>Earning</p>
                                        </div>
                                    </Link>
                                </li>
                                <li
                                    className={location.pathname === "/admin_affiliate" ? "active" : ""}
                                >
                                    <Link to="/admin_affiliate">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={AffiliateSvg} alt="icon" />
                                            <p>Affiliate</p>
                                        </div>
                                    </Link>
                                </li>
                                 <li
                                    className={
                                        location.pathname === "/admin_settings" ? "active" : ""
                                    }
                                >
                                    <Link to="/admin_settings">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={Setting2Svg} alt="icon" />
                                            <p>Settings</p>
                                        </div>
                                    </Link>
                                </li> */}
                                <li
                                    className={
                                        location.pathname === "/admin_customer" ? "active" : ""
                                    }
                                >
                                    <Link to="/admin_customer">
                                        <div className="list_item">
                                            <img className='svg-icon active_icon' src={ProfileSvg} alt='icon' />
                                            <p>Customer</p>
                                        </div>
                                    </Link>
                                </li>
                                {/* <li>
                                    <div className='list_item'>
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <p className="accordion-header accordion_head" id="flush-headingOne">
                                                    <button className="accordion-button collapsed dropdown_sidebar" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <img src={ProfileSvg} alt='icon' />
                                                        <p>Users</p>
                                                    </button>
                                                </p>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <a>User 1</a>
                                                            </li>
                                                            <li>
                                                                <a>User 2</a>
                                                            </li>
                                                            <li>
                                                                <a>User 3</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                <li>
                                    <div className='admin_logout_btn'  onClick={logout}y>
                                        <img src={AdminLogOutSvg} alt='icon' />
                                        <p>Logout</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSidebar